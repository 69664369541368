import React, { useCallback, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import useModal from "./../../hooks/modal";
import { clientEmail } from "./../../graphql/queries/landlordQueries";
import { updateClientEmailMutation } from "./../../graphql/mutations/landlordMutations";
import { resizeImageMutation } from "../../graphql/mutations/systemMutations";
import Permissions, {
  landlordNoAccessFallbackList,
} from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import ImageGalleryModal from "./../../mood-ui/ImageGalleryModal";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "../../mood-ui/Layout";
import PageSpinner from "./../../mood-ui/PageSpinner";
import { FormControls, SaveButton } from "../../mood-ui/Controls";
import { RichTextEditorField, ToggleCheckboxField } from "../../mood-ui/Fields";
import { ColorPicker } from "../../mood-ui/ColorPicker";
import { Heading } from "../../mood-ui/Typography";
import LabelContextSwitch from "../../mood-ui/LabelContextSwitcher";
import {
  SuccessNotification,
  ErrorNotification,
} from "./../../mood-ui/Notifications";

import useBaseFieldSetters from "./../../hooks/baseFieldSetters";
import useHyperState from "./../../hooks/hyperState";
import useFormErrors from "../../hooks/formErrors";
import useNotification from "./../../hooks/notifications";
import { LabelContext } from "../../contexts/LabelContext";

import ClientOverview from "./../../components/reports/ClientOverview";

const CLIENT_EMAIL = gql(clientEmail);
const UPDATE_CLIENT_EMAIL = gql(updateClientEmailMutation);
const RESIZE_IMAGE = gql(resizeImageMutation);

const LandlordClientEmail = ({ entityDetails = null }) => {
  const { id: account_id } = useParams();

  const { protectPage, hasAccess } = usePermissions();

  protectPage(Permissions.Query.ClientEmailSettings, {
    noAccessFallbackList: landlordNoAccessFallbackList,
    variables: { id: account_id },
  });

  const labelContext = useContext(LabelContext);

  const {
    state: formData,
    setState: setFormData,
    stateMethods,
    syncView,
  } = useHyperState("formData", {
    account_id: 0,
    label_id: 0,
    logo_src: "",
    primary_colour: "",
    secondary_colour: "",
    button_colour: "",
    button_text_colour: "",
    basic_activation_message_1: "",
    basic_activation_message_2: "",
    basic_activation_message_3: "",
    premium_activation_message_1: "",
    premium_activation_message_2: "",
    premium_activation_message_3: "",
    feedback_campaigns: false,
    custom_feedback: false,
  });

  const {
    setFieldError,
    fieldError,
    formHasErrors,
    resolveMutationErrors,
    clearError,
  } = useFormErrors();

  const {
    setFieldValue: handleFieldOnChangeProxied,
    toggleCheckbox: handleToggleCheckbox,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const handleFieldOnChange = (e) => {
    clearError(e.target.name);
    if (e.target?.checkValidity && !e.target?.checkValidity()) {
      setFieldError(
        e.target.name,
        e.target.title || e.target.validationMessage || "Input has an error"
      );
      setDraft(false);
    } else if (formHasErrors()) {
      setDraft(false);
    } else {
      setDraft(true);
    }
    handleFieldOnChangeProxied(e);
  };

  const { modalState, showModal, closeModal } = useModal();

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const { loading, error } = useQuery(CLIENT_EMAIL, {
    variables: {
      account_id: parseInt(account_id),
      label_id: parseInt(labelContext.activeLabel),
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.clientEmail) return false;
      setFormData({ ...result.clientEmail });
    },
  });

  const [
    update,
    { data: updateResult, loading: isUpdating, error: updateError },
  ] = useMutation(UPDATE_CLIENT_EMAIL, {
    onCompleted: (result) => {
      displayNotification({
        heading: "Update OK",
        message: "Successfully updated client email settings",
        secondsToDisplay: 1,
      });
    },
    onError: (e) => {
      displayErrorNotification({
        heading: "Error",
        message: "Failed to update client email settings",
        secondsToDisplay: 2,
      });
      resolveMutationErrors(e);
    },
  });

  const [resizeLogo] = useMutation(RESIZE_IMAGE, {
    onCompleted: (result) => {
      displayNotification({
        heading: "Processing Image",
        message: "Image was successfully resized",
        secondsToDisplay: 1,
      });
      handleFieldOnChange({
        target: {
          name: "logo_src",
          value: result.resizeImage.public_url,
        },
      });
    },
    onError: (e) => {
      console.log("e");
      displayErrorNotification({
        heading: "Processing Image",
        message: "Failed to resize image",
        secondsToDisplay: 2,
      });
      resolveMutationErrors(e);
    },
  });

  const handleLogoSelectModal = useCallback(() => {
    showModal({
      onConfirm: (file) => {
        displayNotification({
          heading: "Processing Image",
          message: "Resizing selected image",
          secondsToDisplay: 1,
        });
        resizeLogo({
          variables: {
            file_id: file.id,
            width: 800,
            height: 200,
          },
        });
        closeModal();
      },
      onCancel: () => {
        closeModal();
      },
    });
  }, []);

  const handleSave = () => {
    const data = { ...formData };
    update({ variables: data });
  };

  const [isDraft, setDraft] = useState(false);

  if (error) return <div>Error...</div>;
  // if (loading) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Email settings"
              description="Control how member emails behave for this client. Changes
                            made on this page can take time to take effect across all 
                            of the clients users."
            />
            <ClientOverview
              entityDetails={entityDetails}
              groupFilter={["users", "growth"]}
              dense
            />
          </Col>
        </Row>
        <FormContainer>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Email content groups"
                      description="Master switches for certain types of email content."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={6} extraStyles={""}>
                    <ToggleCheckboxField
                      loading={loading}
                      id="feedback_campaigns"
                      name="feedback_campaigns"
                      label="Prize draw emails"
                      isChecked={formData?.feedback_campaigns}
                      onChange={(e) => {
                        setDraft(true);
                        handleToggleCheckbox(e);
                      }}
                      error={fieldError("feedback_campaigns")}
                      description="Does not remove feature but stops email content"
                    />
                  </Col>
                  <Col width={6} extraStyles={""}>
                    <ToggleCheckboxField
                      loading={loading}
                      id="custom_feedback"
                      name="custom_feedback"
                      label="Vaboo Compare emails"
                      isChecked={formData?.custom_feedback}
                      onChange={(e) => {
                        setDraft(true);
                        handleToggleCheckbox(e);
                      }}
                      error={fieldError("custom_feedback")}
                      description="Does not remove offers but stops email content"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Basic activation emails"
                      description="A sentence that may be inserted into activation emails."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row tweaks="gap-x-3">
                  <Col width={4} extraStyles={""}>
                    <RichTextEditorField
                      loading={loading}
                      name="basic_activation_message_1"
                      label="Email 1"
                      value={formData?.basic_activation_message_1 || ""}
                      onChange={handleFieldOnChange}
                      error={fieldError("basic_activation_message_1")}
                      isRequired={true}
                      minlength="24"
                      maxlength="250"
                      rows={5}
                    />
                  </Col>
                  <Col width={4} extraStyles={""}>
                    <RichTextEditorField
                      loading={loading}
                      name="basic_activation_message_2"
                      label="Email 2"
                      value={formData?.basic_activation_message_2 || ""}
                      onChange={handleFieldOnChange}
                      error={fieldError("basic_activation_message_2")}
                      isRequired={true}
                      minlength="24"
                      maxlength="250"
                      rows={5}
                    />
                  </Col>
                  <Col width={4} extraStyles={""}>
                    <RichTextEditorField
                      loading={loading}
                      name="basic_activation_message_3"
                      label="Email 3"
                      value={formData?.basic_activation_message_3 || ""}
                      onChange={handleFieldOnChange}
                      error={fieldError("basic_activation_message_3")}
                      isRequired={true}
                      minlength="24"
                      maxlength="250"
                      rows={5}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Premium activation emails"
                      description="A sentence that may be inserted into activation emails."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row tweaks="gap-x-3">
                  <Col width={4} extraStyles={""}>
                    <RichTextEditorField
                      loading={loading}
                      name="premium_activation_message_1"
                      label="Email 1"
                      value={formData?.premium_activation_message_1 || ""}
                      onChange={handleFieldOnChange}
                      error={fieldError("premium_activation_message_1")}
                      isRequired={true}
                      minlength="24"
                      maxlength="250"
                      rows={5}
                    />
                  </Col>
                  <Col width={4} extraStyles={""}>
                    <RichTextEditorField
                      loading={loading}
                      name="premium_activation_message_2"
                      label="Email 2"
                      value={formData?.premium_activation_message_2 || ""}
                      onChange={handleFieldOnChange}
                      error={fieldError("premium_activation_message_2")}
                      isRequired={true}
                      minlength="24"
                      maxlength="250"
                      rows={5}
                    />
                  </Col>
                  <Col width={4} extraStyles={""}>
                    <RichTextEditorField
                      loading={loading}
                      name="premium_activation_message_3"
                      label="Email 3"
                      value={formData?.premium_activation_message_3 || ""}
                      onChange={handleFieldOnChange}
                      error={fieldError("premium_activation_message_3")}
                      isRequired={true}
                      minlength="24"
                      maxlength="250"
                      rows={5}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Email branding"
                      description="Hover on a colour or click the logo to make changes. 
                                  The logo will be resized to a height of 200px and
                                  a width of 800px."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row tweaks="gap-x-2ss">
                  <Col width={4}>
                    <ColorPicker
                      loading={loading}
                      name="primary_colour"
                      label="Primary colour"
                      value={formData?.primary_colour}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                        syncView();
                      }}
                    />
                    <div className="w-full py-1.5"></div>
                    <ColorPicker
                      loading={loading}
                      name="secondary_colour"
                      label="Secondary colour"
                      value={formData?.secondary_colour}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                        syncView();
                      }}
                    />
                    <div className="w-full py-1.5"></div>
                    <ColorPicker
                      loading={loading}
                      name="button_colour"
                      label="Button colour"
                      value={formData?.button_colour}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                        syncView();
                      }}
                    />
                    <div className="w-full py-1.5"></div>
                    <ColorPicker
                      loading={loading}
                      name="button_text_colour"
                      label="Button text colour"
                      value={formData?.button_text_colour}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                        syncView();
                      }}
                    />
                  </Col>
                  <Col
                    width={8}
                    extraStyles="bg-[#F5F5F5] py-3 px-6 flex items-around align-center flex-wrap select-none"
                  >
                    <div
                      className={`
                        bg-[${formData?.primary_colour}] 
                        flex items-center justify-center relative 
                        w-full aspect-[7/1] 
                        p-1
                        text-center 
                        rounded-t-md
                        border-b-[6px] border-b-[${formData?.button_colour}] 
                    `}
                    >
                      <img
                        src={
                          formData?.logo_src && formData?.logo_src.length > 0
                            ? formData.logo_src
                            : ""
                        }
                        style={{
                          minWidth: "120px",
                          minHeight: "20px",
                          maxWidth: "50%",
                          cursor: "pointer",
                          padding: "0px",
                        }}
                        alt="Logo"
                        onClick={handleLogoSelectModal}
                        className={`hover:outline outline-1 outline-[${formData?.secondary_colour}]`}
                      />
                    </div>
                    <div className="w-full bg-white transition-all ease-in-out flex rounded-b-md">
                      <div
                        className={`w-full justify-center flex-row flex bg-[${formData?.bg_color}]`}
                      >
                        <div className="text-slate text-xs font-light text-left self-center p-6 select-none">
                          <p>
                            Hey there,
                            <br />
                            <br />
                            This is an example of our email style using the
                            brand colours for this client's platform.
                            <br />
                            <br />
                            In most cases this will be the same as the platform
                            branding.
                          </p>
                          <button
                            type="button"
                            className={`rounded-sm mt-4 justify-center py-1 px-4 border border-transparent text-xs leading-2 
                            text-[${formData?.button_text_colour}] bg-[${formData?.button_colour}] pointer-events-none`}
                          >
                            Button
                          </button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormData>
          <FormControls spaced={false}>
            <LabelContextSwitch />
            {hasAccess(Permissions.Mutation.UpdateClient) && (
              <SaveButton
                onClick={handleSave}
                disabled={!isDraft || formHasErrors()}
                loading={isUpdating}
              />
            )}
          </FormControls>
        </FormContainer>
      </BlockContainer>
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
      <ImageGalleryModal {...modalState} />
    </PageContainer>
  );
};

export default LandlordClientEmail;
