import React, { useState } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  clientPackagePricing,
  calculateClientPackagePricing,
} from "./../../../graphql/queries/billingQueries";
import { updateClientPackagePricingMutation } from "./../../../graphql/mutations/billingMutations";
import Permissions, {
  landlordNoAccessFallbackList,
} from "../../../configs/permissions";
import usePermissions from "../../../hooks/permissions";
import { Row, Col } from "../../../mood-ui/Layout";
import { FormControls, SaveButton } from "../../../mood-ui/Controls";
import { NumberField } from "../../../mood-ui/Fields";
import useBaseFieldSetters from "./../../../hooks/baseFieldSetters";
import useHyperState from "./../../../hooks/hyperState";
import useFormErrors from "../../../hooks/formErrors";
import PriceCalculator from "./PriceCalculator";

const QUERY_PRICING = gql(clientPackagePricing);
const CALCULATE_PRICE = gql(calculateClientPackagePricing);
const UPDATE_PRICING = gql(updateClientPackagePricingMutation);

const PackagePricing = ({
  clientId,
  paymentSettings,
  isDraft,
  setDraft,
  parentFormHasErrors,
  onSuccess = () => {},
  onError = () => {},
}) => {
  const { protectPage, hasAccess } = usePermissions();
  protectPage(Permissions.Query.ClientPaymentSettings, {
    noAccessFallbackList: landlordNoAccessFallbackList,
    variables: { id: clientId },
  });

  const [computedPrice, setComputedPrice] = useState(0);

  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("packagePricing", {
    units: 0,
    price: 0,
  });

  const {
    setFieldError,
    formHasErrors,
    fieldError,
    resolveMutationErrors,
    clearError,
  } = useFormErrors();

  const { setFieldValue: handleFieldOnChangeProxied } = useBaseFieldSetters(
    formData,
    stateMethods,
    clearError
  );

  const handleFieldOnChange = (e) => {
    clearError(e.target.name);
    if (e.target?.checkValidity && !e.target?.checkValidity()) {
      setFieldError(
        e.target.name,
        e.target.title || e.target.validationMessage || "Input has an error"
      );
      setDraft(false);
    } else if (formHasErrors()) {
      setDraft(false);
    } else {
      setDraft(true);
    }
    handleFieldOnChangeProxied(e);
  };

  const { loading, error } = useQuery(QUERY_PRICING, {
    variables: {
      client_id: parseInt(clientId),
    },
    // fetchPolicy: "cache-and-network",
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      if (!result || !result.clientPackagePricing) return false;
      setFormData({
        units: result.clientPackagePricing.units,
        price: result.clientPackagePricing.price,
      });
    },
  });

  const [update, { loading: isUpdating }] = useMutation(UPDATE_PRICING, {
    onCompleted: (result) => onSuccess(),
    onError: (e) => {
      onError();
      resolveMutationErrors(e);
    },
  });

  const [calculatePrice] = useLazyQuery(CALCULATE_PRICE, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      setComputedPrice(result.calculateClientPackagePricing.amount);
    },
    onError: (e) => onError(),
  });

  const handleSave = () => {
    const data = { ...paymentSettings, ...formData };
    delete data.__typename;
    delete data.account_id;
    update({ variables: data });
  };

  if (loading) return <div></div>;
  if (error) return <div>Error...</div>;

  return (
    <React.Fragment>
      <Row>
        <Col width={6}>
          <NumberField
            name="units"
            label="Users per block"
            value={formData.units}
            min={1}
            max={20000}
            step={1}
            onChange={(e) => {
              setDraft((d) => true);
              handleFieldOnChange(e);
            }}
            error={fieldError("units")}
            isRequired={true}
            description="Number of user accounts included"
          />
        </Col>
        <Col width={6}>
          <NumberField
            name="price"
            label="Price per Block"
            value={formData.price}
            min={0}
            step={0.01}
            gbp
            onChange={(e) => {
              setDraft((d) => true);
              handleFieldOnChange(e);
            }}
            error={fieldError("price")}
            isRequired={true}
            description="Fixed cost of block"
          />
        </Col>
      </Row>
      <FormControls>
        {!isUpdating &&
          hasAccess(Permissions.Mutation.UpdateClientPaymentSettings) && (
            <SaveButton onClick={handleSave} />
          )}
      </FormControls>
      <FormControls spaced>
        <PriceCalculator
          paymentSettings={paymentSettings}
          pricing={formData}
          computedPrice={computedPrice}
          onCalculate={calculatePrice}
        />
        {hasAccess(Permissions.Mutation.UpdateClientPaymentSettings) && (
          <SaveButton
            onClick={handleSave}
            loading={isUpdating}
            disabled={formHasErrors() || parentFormHasErrors() || !isDraft}
          />
        )}
      </FormControls>
    </React.Fragment>
  );
};

export default PackagePricing;
