import React, { useCallback } from "react";
import Moment from "react-moment";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  landlordOfferTags,
  landlordOfferExclusionTags,
  landlordOffers,
} from "./../../graphql/queries/landlordQueries";
import {
  activateOfferTagMutation,
  deactivateOfferTagMutation,
  activateOfferExclusionTagMutation,
  deactivateOfferExclusionTagMutation,
  includeOffersMutation,
  excludeOffersMutation,
} from "./../../graphql/mutations/landlordMutations";
import Permissions, {
  landlordNoAccessFallbackList,
} from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import PageSpinner from "./../../mood-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "../../mood-ui/Layout";
import { PageControls, ListItemCheckbox } from "./../../mood-ui/Controls";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
  TableSelectedActions,
} from "./../../mood-ui/DataTable";
import LandlordOffersListActions from "./../../components/list-secondary-actions/LandlordOffersListActions";
import useListSelection from "./../../hooks/rowSelection";
import { ToggleCheckboxField } from "../../mood-ui/Fields";
import { Heading, ColorLabel } from "../../mood-ui/Typography";
import {
  SuccessNotification,
  ErrorNotification,
} from "./../../mood-ui/Notifications";

import useNotification from "./../../hooks/notifications";

const LANDLORD_OFFER_TAGS = gql(landlordOfferTags);
const LANDLORD_OFFER_EXCLUSION_TAGS = gql(landlordOfferExclusionTags);
const LANDLORD_OFFERS = gql(landlordOffers);
const ACTIVATE_OFFER_TAG = gql(activateOfferTagMutation);
const DEACTIVATE_OFFER_TAG = gql(deactivateOfferTagMutation);
const ACTIVATE_OFFER_EXCLUSION_TAG = gql(activateOfferExclusionTagMutation);
const DEACTIVATE_OFFER_EXCLUSION_TAG = gql(deactivateOfferExclusionTagMutation);
const INCLUDE_OFFERS = gql(includeOffersMutation);
const EXCLUDE_OFFERS = gql(excludeOffersMutation);

const LandlordOffers = () => {
  const { id } = useParams();
  const { protectPage } = usePermissions();
  protectPage(Permissions.Mutation.UpdateClientOffers, {
    noAccessFallbackList: landlordNoAccessFallbackList,
    variables: { id },
  });
  const {
    selectedRows,
    isRowSelected: rowSelected,
    toggleRow: toggleRowSelection,
    clearSelection,
    isAllRowsSelected,
    toggleAllRows,
  } = useListSelection();

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const {
    data: tagsData,
    // loading: loadingTags
  } = useQuery(LANDLORD_OFFER_TAGS, {
    variables: {
      account_id: id,
    },
    fetchPolicy: "network-only",
  });

  const { data: exclusionTagsData } = useQuery(LANDLORD_OFFER_EXCLUSION_TAGS, {
    variables: {
      account_id: id,
    },
    fetchPolicy: "network-only",
  });

  const { data: exclusionsData, refetch: refetchExclusions } = useQuery(
    LANDLORD_OFFERS,
    {
      variables: {
        account_id: id,
      },
      fetchPolicy: "network-only",
    }
  );

  const updateTagStatuses = useCallback((cache, data, mutationKey, status) => {
    const { id: tagId } = data[mutationKey].tag_object;
    const prevData = cache.readQuery({
      query: LANDLORD_OFFER_TAGS,
      variables: { account_id: id },
    });

    const updatedTags = prevData.landlordOfferTags.map((tag) => {
      const updatedTag = { ...tag };
      if (updatedTag.tag_object.id === tagId) {
        updatedTag.is_active = status;
      }
      return updatedTag;
    });
    cache.writeQuery({
      query: LANDLORD_OFFER_TAGS,
      variables: { account_id: id },
      data: { ...prevData, landlordOfferTags: updatedTags },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateExclusionTagStatuses = useCallback(
    (cache, data, mutationKey, status) => {
      const { id: tagId } = data[mutationKey].tag_object;
      const prevData = cache.readQuery({
        query: LANDLORD_OFFER_EXCLUSION_TAGS,
        variables: { account_id: id },
      });

      const updatedTags = prevData.landlordOfferExclusionTags.map((tag) => {
        const updatedTag = { ...tag };
        if (updatedTag.tag_object.id === tagId) {
          updatedTag.is_active = status;
        }
        return updatedTag;
      });
      cache.writeQuery({
        query: LANDLORD_OFFER_EXCLUSION_TAGS,
        variables: { account_id: id },
        data: { ...prevData, landlordOfferExclusionTags: updatedTags },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  const [activateTag, { loading: activatingOfferTags }] = useMutation(
    ACTIVATE_OFFER_TAG,
    {
      onCompleted: (result) => {
        displayNotification({
          heading: "Tag Activation",
          message: "Successfully activated offer tag",
          secondsToDisplay: 1,
        });
        refetchExclusions();
      },
      onError: (e) => {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to activate offer tag",
          secondsToDisplay: 2,
        });
      },
      update: (cache, { data }) => {
        if (!data.activateOfferTag) return false;
        updateTagStatuses(cache, data, "activateOfferTag", true);
      },
    }
  );

  const [deactivateTag, { loading: deactivatingOfferTags }] = useMutation(
    DEACTIVATE_OFFER_TAG,
    {
      onCompleted: (result) => {
        displayNotification({
          heading: "Tag Deactivation",
          message: "Successfully deactivated offer tag",
          secondsToDisplay: 1,
        });
        refetchExclusions();
      },
      onError: (e) => {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to deactivate offer tag",
          secondsToDisplay: 2,
        });
      },
      update: (cache, { data }) => {
        if (!data.deactivateOfferTag) return false;
        updateTagStatuses(cache, data, "deactivateOfferTag", false);
      },
    }
  );

  const [activateExclusionTag, { loading: activatingOfferExclusionTag }] =
    useMutation(ACTIVATE_OFFER_EXCLUSION_TAG, {
      onCompleted: (result) => {
        displayNotification({
          heading: "Exclusion Tag Activation",
          message: "Successfully activated offer exclusion tag",
          secondsToDisplay: 1,
        });
      },
      onError: (e) => {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to activate offer exclusion tag",
          secondsToDisplay: 2,
        });
      },
      update: (cache, { data }) => {
        if (!data.activateOfferExclusionTag) return false;
        updateExclusionTagStatuses(
          cache,
          data,
          "activateOfferExclusionTag",
          true
        );
      },
    });

  const [deactivateExclusionTag, { loading: deactivatingOfferExclusionTag }] =
    useMutation(DEACTIVATE_OFFER_EXCLUSION_TAG, {
      onCompleted: (result) => {
        displayNotification({
          heading: "Exclusion Tag Deactivation",
          message: "Successfully deactivated offer exclusion tag",
          secondsToDisplay: 1,
        });
      },
      onError: (e) => {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to deactivate offer exclusion tag",
          secondsToDisplay: 2,
        });
      },
      update: (cache, { data }) => {
        if (!data.deactivateOfferExclusionTag) return false;
        updateExclusionTagStatuses(
          cache,
          data,
          "deactivateOfferExclusionTag",
          false
        );
      },
    });

  const updateOfferStatuses = useCallback(
    (cache, data, mutationKey, status) => {
      const offerIds = data[mutationKey].map((offer) => offer.offer_object.id);

      const prevData = cache.readQuery({
        query: LANDLORD_OFFERS,
        variables: { account_id: id },
      });

      const updatedOffers = prevData.landlordOffers.map((offer) => {
        const updatedOffer = { ...offer };
        if (offerIds.indexOf(updatedOffer.offer_object.id) > -1) {
          updatedOffer.is_excluded = status;
        }
        return updatedOffer;
      });

      cache.writeQuery({
        query: LANDLORD_OFFERS,
        variables: { account_id: id },
        data: { ...prevData, landlordOffers: updatedOffers },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  const [includeOffers, { loading: includingOffers }] = useMutation(
    INCLUDE_OFFERS,
    {
      onCompleted: (result) => {
        displayNotification({
          heading: "Offers Included",
          message: "Successfully included selected offers",
          secondsToDisplay: 1,
        });
        clearSelection();
      },
      onError: (e) => {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to include selected offers",
          secondsToDisplay: 2,
        });
      },
      update: (cache, { data }) => {
        if (!data.includeOffers) return false;
        updateOfferStatuses(cache, data, "includeOffers", false);
      },
    }
  );

  const [excludeOffers, { loading: excludingOffers }] = useMutation(
    EXCLUDE_OFFERS,
    {
      onCompleted: (result) => {
        displayNotification({
          heading: "Offers Excluded",
          message: "Successfully excluded selected offers",
          secondsToDisplay: 1,
        });
        clearSelection();
      },
      onError: (e) => {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to exclude selected offers",
          secondsToDisplay: 2,
        });
      },
      update: (cache, { data }) => {
        if (!data.excludeOffers) return false;
        updateOfferStatuses(cache, data, "excludeOffers", true);
      },
    }
  );

  const handleTT = (e) => {
    const { value: tagId } = e.target;
    const tagObj = tagsData.landlordOfferTags.filter(
      (tag) => tag.tag_object.id === tagId
    )[0];
    const newValue = !tagObj.is_active;

    if (newValue) {
      activateTag({
        variables: { account_id: parseInt(id), tag_id: parseInt(tagId) },
      });
    } else {
      deactivateTag({
        variables: { account_id: parseInt(id), tag_id: parseInt(tagId) },
      });
    }
  };

  const handleETT = (e) => {
    const { value: tagId } = e.target;
    const tagObj = exclusionTagsData.landlordOfferExclusionTags.filter(
      (tag) => tag.tag_object.id === tagId
    )[0];
    const newValue = !tagObj.is_active;

    if (newValue) {
      activateExclusionTag({
        variables: { account_id: parseInt(id), tag_id: parseInt(tagId) },
      });
    } else {
      deactivateExclusionTag({
        variables: { account_id: parseInt(id), tag_id: parseInt(tagId) },
      });
    }
  };

  const handleIncludeOffers = () => {
    includeOffers({
      variables: {
        account_id: parseInt(id),
        ids: selectedRows.map((r) => parseInt(r)),
      },
    });
    refetchExclusions();
  };

  const handleExcludeOffers = () => {
    excludeOffers({
      variables: {
        account_id: parseInt(id),
        ids: selectedRows.map((r) => parseInt(r)),
      },
    });
    refetchExclusions();
  };

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Platform content"
              description="Control which offers and reward cards are visible in
                          this platform."
            />
          </Col>
        </Row>
        <FormContainer>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={12}>
                <Heading
                  size="md"
                  text="Exclusion tags"
                  description="Exclude content that has a certain exclusion tag."
                />
              </Col>
              <Col width={12}>
                <Row tweaks="gap-0 sm:grid-cols-5s py-1 px-px">
                  {!tagsData || !exclusionTagsData || !exclusionsData ? (
                    <Col width={9} extraStyles="h-[78px]">
                      <PageSpinner fill clean />
                    </Col>
                  ) : (
                    exclusionTagsData.landlordOfferExclusionTags &&
                    [...exclusionTagsData.landlordOfferExclusionTags]
                      .sort((a, b) =>
                        a.tag_object.slug > b.tag_object.slug
                          ? 1
                          : a.tag_object.slug < b.tag_object.slug
                          ? -1
                          : 0
                      )
                      .map((tag, index) => (
                        <Col width={2} key={tag.tag_object.id}>
                          <ToggleCheckboxField
                            key={index}
                            id={tag.tag_object.id}
                            label={tag.tag_object.title}
                            description={tag.tag_object.slug}
                            isChecked={tag.is_active}
                            onChange={(e) => {
                              handleETT(e);
                            }}
                            flush
                          />
                        </Col>
                      ))
                  )}
                </Row>
              </Col>
            </Row>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={12}>
                <Heading
                  size="md"
                  text="Category tags"
                  description="Include offers that have a certain category tag."
                />
              </Col>
              <Col width={12}>
                <Row tweaks="gap-0 sm:grid-cols-5s py-1 px-px">
                  {!tagsData || !exclusionTagsData || !exclusionsData ? (
                    <Col width={12} extraStyles="h-[555px]">
                      <PageSpinner fill clean />
                    </Col>
                  ) : (
                    tagsData.landlordOfferTags &&
                    [...tagsData.landlordOfferTags]
                      .sort((a, b) =>
                        a.tag_object.slug > b.tag_object.slug
                          ? 1
                          : a.tag_object.slug < b.tag_object.slug
                          ? -1
                          : 0
                      )
                      .map((tag, index) => (
                        <Col width={2} key={tag.tag_object.id}>
                          <ToggleCheckboxField
                            key={index}
                            id={tag.tag_object.id}
                            label={tag.tag_object.title}
                            description={tag.tag_object.slug}
                            isChecked={tag.is_active}
                            onChange={handleTT}
                            flush
                          />
                        </Col>
                      ))
                  )}
                </Row>
              </Col>
            </Row>
            <Row tweaks="border-[#dfe2e8] py-7 mx-5">
              <Col width={12}>
                <Heading
                  size="md"
                  text="Offers available"
                  description="This list includes all offers available based on the above tag selection.
                                Tou can exclude specific offers if the tag system didn't meet your requirements."
                />
              </Col>
            </Row>
            {!tagsData || !exclusionTagsData || !exclusionsData ? (
              <Col width={12} extraStyles="h-[1555px]">
                <PageSpinner fill clean />
              </Col>
            ) : (
              exclusionsData &&
              exclusionsData.landlordOffers &&
              exclusionsData.landlordOffers.length > 0 && (
                <TableContainer tweaks="px-5 pb-6">
                  <TableView>
                    <THeading>
                      <TRow>
                        <THCol tweaks="w-4 text-center">
                          <ListItemCheckbox
                            onChange={() =>
                              toggleAllRows(
                                exclusionsData.landlordOffers.map(
                                  (offer) => offer.offer_object.id
                                )
                              )
                            }
                            isChecked={
                              isAllRowsSelected(
                                exclusionsData.landlordOffers.map(
                                  (offer) => offer.offer_object.id
                                )
                              )
                                ? true
                                : false
                            }
                          />
                        </THCol>
                        <THCol tweaks="w-8 text-left" name="image" value="" />
                        <THCol tweaks="text-left" name="title" value="Title" />
                        <THCol
                          tweaks="text-center"
                          name="provider"
                          value="Type"
                        />
                        <THCol tweaks="text-left" name="code" value="Slug" />
                        <THCol tweaks="text-left" name="tags" value="Tags" />
                        <THCol
                          tweaks="text-left"
                          name="exclusion_tags"
                          value="x-Tags"
                        />
                        <THCol
                          tweaks="text-center"
                          name="status"
                          value="Status"
                        />
                        <THCol
                          tweaks="text-center"
                          name="start_date"
                          value="Starts"
                        />
                        <THCol
                          tweaks="text-center"
                          name="end_date"
                          value="Ends"
                        />
                        <THCol
                          tweaks="text-center"
                          name="is_excluded"
                          value="Rule"
                        />
                      </TRow>
                    </THeading>
                    <TContent>
                      {[...exclusionsData.landlordOffers]
                        .sort((a, b) =>
                          a.offer_object.code > b.offer_object.code
                            ? 1
                            : a.offer_object.code < b.offer_object.code
                            ? -1
                            : 0
                        )
                        .sort((a, b) =>
                          a.is_excluded < b.is_excluded
                            ? 1
                            : a.is_excluded > b.is_excluded
                            ? -1
                            : 0
                        )
                        .map((offer, index) => (
                          <TRow
                            key={offer.offer_object.id}
                            isSelected={rowSelected(offer.offer_object.id)}
                          >
                            <TCol>
                              <ListItemCheckbox
                                onChange={() =>
                                  toggleRowSelection(offer.offer_object.id)
                                }
                                isChecked={
                                  rowSelected(offer.offer_object.id)
                                    ? true
                                    : false
                                }
                              />
                            </TCol>
                            <TCol tweaks="min-w-[37px] max-w-[37px] text-center pr-0 pl-0 pb-0">
                              <img
                                src={offer.offer_object.image}
                                alt=""
                                className={`-mt-2 -mr-[5px] h-[37px] w-[37px] ${
                                  rowSelected(offer.offer_object.id)
                                    ? "opacity-75"
                                    : ""
                                }`}
                              />
                            </TCol>
                            <TCol tweaks="max-w-[200px] font-mono slashed-zero tabular-nums text-left text-xs">
                              <abbr title={offer.offer_object.admin_title}>
                                {offer.offer_object.admin_title}
                              </abbr>
                            </TCol>
                            <TCol tweaks="max-w-[120px] text-center text-opacity-90">
                              <abbr title={offer.offer_object.provider}>
                                {offer.offer_object.provider == "Tillo" ? (
                                  <span className="mdi mdi-credit-card-outline text-lg leading-none"></span>
                                ) : (
                                  <span className="mdi mdi-cursor-default-outline text-lg leading-none"></span>
                                )}
                              </abbr>
                            </TCol>
                            <TCol tweaks="max-w-[120px] font-mono slashed-zero tabular-nums text-left text-xs">
                              <abbr title={offer.offer_object.code}>
                                {offer.offer_object.code}
                              </abbr>
                            </TCol>
                            <TCol tweaks="max-w-[160px] font-mono slashed-zero tabular-nums text-left text-xs">
                              <abbr
                                title={
                                  offer.offer_object.tags &&
                                  offer.offer_object.tags
                                    .map((tag) => tag.slug)
                                    .join(", ")
                                }
                              >
                                {offer.offer_object.tags &&
                                  offer.offer_object.tags
                                    .map((tag) => tag.slug)
                                    .join(", ")}
                              </abbr>
                            </TCol>
                            <TCol tweaks="max-w-[80px] font-mono slashed-zero tabular-nums text-left text-xs">
                              <abbr
                                title={
                                  offer.offer_object.exclusionTags &&
                                  offer.offer_object.exclusionTags
                                    .map((tag) => tag.slug)
                                    .join(", ")
                                }
                              >
                                {offer.offer_object.exclusionTags &&
                                  offer.offer_object.exclusionTags
                                    .map((tag) => tag.slug)
                                    .join(", ")}
                              </abbr>
                            </TCol>
                            <TCol
                              tweaks={`w-[50px] font-mono slashed-zero tabular-nums text-center uppercase text-xs
                            ${
                              offer?.offer_object?.status == "Inactive"
                                ? " text-rose-500 bg-rose-100"
                                : offer?.offer_object?.status == "Active"
                                ? " text-emerald-600 bg-emerald-200"
                                : " text-opacity-20"
                            }`}
                            >
                              {offer.offer_object.status}
                            </TCol>
                            <TCol
                              tweaks={`
                            font-mono slashed-zero tabular-nums text-center uppercase text-xs 
                            ${
                              new Date(offer.offer_object.start_date).setHours(
                                0,
                                0,
                                0,
                                0
                              ) < new Date().setHours(0, 0, 0, 0)
                                ? ""
                                : " text-rose-600 bg-rose-100"
                            }
                          `}
                            >
                              <Moment
                                format="DD&middot;MM&middot;YY"
                                date={offer.offer_object.start_date}
                              />
                            </TCol>
                            <TCol
                              tweaks={`
                            font-mono slashed-zero tabular-nums text-center uppercase text-xs " 
                            ${
                              new Date(offer.offer_object.end_date).setHours(
                                0,
                                0,
                                0,
                                0
                              ) > new Date().setHours(0, 0, 0, 0)
                                ? ""
                                : " text-rose-600 bg-rose-100"
                            }
                          `}
                            >
                              <Moment
                                format="DD&middot;MM&middot;YY"
                                date={offer.offer_object.end_date}
                              />
                            </TCol>
                            <TCol
                              tweaks={`w-[50px] font-mono slashed-zero tabular-nums text-center uppercase text-xs
                            ${
                              offer?.is_excluded
                                ? " text-rose-500 bg-rose-100"
                                : " text-emerald-600 bg-emerald-200"
                            }`}
                            >
                              {offer.is_excluded ? "Excluded" : "Included"}
                            </TCol>
                          </TRow>
                        ))}
                    </TContent>
                  </TableView>
                  <PageControls spaced>
                    <Row tweaks="w-full pl-5">
                      <Col width={2}>
                        <TableSelectedActions>
                          {selectedRows && selectedRows.length > 0 && (
                            <LandlordOffersListActions
                              onClearSelection={clearSelection}
                              onExclude={handleExcludeOffers}
                              onInclude={handleIncludeOffers}
                            />
                          )}
                        </TableSelectedActions>
                      </Col>
                      <Col width={4} extraStyles=""></Col>
                      <Col width={6}>
                        <div className="bg-red-3s00 text-right py-[6px] px-4 text-sm text-gray-400">
                          {(activatingOfferTags ||
                            deactivatingOfferTags ||
                            includingOffers ||
                            excludingOffers ||
                            activatingOfferExclusionTag ||
                            deactivatingOfferExclusionTag) && (
                            <span className="mdi mdi-loading mdi-spin mr-2 text-md leading-none"></span>
                          )}
                          {!activatingOfferTags &&
                            !deactivatingOfferTags &&
                            !includingOffers &&
                            !excludingOffers &&
                            !activatingOfferExclusionTag &&
                            !deactivatingOfferExclusionTag && (
                              <span>
                                <span className="mr-2 bg-emerald-200 text-emerald-600 px-2 py-1 font-mono slashed-zero tabular-nums text-center">
                                  {
                                    exclusionsData.landlordOffers.filter(
                                      (offer) => {
                                        return (
                                          !offer?.is_excluded &&
                                          offer?.offer_object?.status ==
                                            "Active" &&
                                          new Date(
                                            offer.offer_object.start_date
                                          ).setHours(0, 0, 0, 0) <
                                            new Date().setHours(0, 0, 0, 0) &&
                                          new Date(
                                            offer.offer_object.end_date
                                          ).setHours(0, 0, 0, 0) >
                                            new Date().setHours(0, 0, 0, 0) &&
                                          offer.offer_object.provider ==
                                            "Standard"
                                        );
                                      }
                                    ).length
                                  }
                                </span>
                                Online offers
                                <span className="ml-4 mr-2 bg-emerald-200 text-emerald-600 px-2 py-1 font-mono slashed-zero tabular-nums text-center">
                                  {
                                    exclusionsData.landlordOffers.filter(
                                      (offer) => {
                                        return (
                                          !offer?.is_excluded &&
                                          offer?.offer_object?.status ==
                                            "Active" &&
                                          new Date(
                                            offer.offer_object.start_date
                                          ).setHours(0, 0, 0, 0) <
                                            new Date().setHours(0, 0, 0, 0) &&
                                          new Date(
                                            offer.offer_object.end_date
                                          ).setHours(0, 0, 0, 0) >
                                            new Date().setHours(0, 0, 0, 0) &&
                                          offer.offer_object.provider == "Tillo"
                                        );
                                      }
                                    ).length
                                  }
                                </span>
                                Reward cards
                              </span>
                            )}
                        </div>
                        {/* <Pagination onPrev={prevPage} onNext={nextPage} /> */}
                      </Col>
                    </Row>
                  </PageControls>
                </TableContainer>
              )
            )}
          </FormData>
        </FormContainer>
      </BlockContainer>
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default LandlordOffers;
