import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import useDataProvider from "../../hooks/dataProvider";
import gql from "graphql-tag";
import { clientExtension } from "../../graphql/queries/landlordQueries";
import { updateClientExtension } from "../../graphql/mutations/landlordMutations";
import Permissions, {
  landlordNoAccessFallbackList,
} from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import {
  ErrorNotification,
  SuccessNotification,
} from "../../mood-ui/Notifications";
import useBaseFieldSetters from "../../hooks/baseFieldSetters";
import useHyperState from "../../hooks/hyperState";
import useFormErrors from "../../hooks/formErrors";
import useNotification from "../../hooks/notifications";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "../../mood-ui/Layout";
import { FormControls, SaveButton } from "../../mood-ui/Controls";
import { NumberField, DateTimeField, SelectField } from "../../mood-ui/Fields";
import { Heading } from "../../mood-ui/Typography";
import ClientOverview from "../../components/reports/ClientOverview";
import moment from "moment";

const CLIENT_EXTENSION = gql(clientExtension);

const UPDATE_CLIENT_EXTENSION = gql(updateClientExtension);

const serviceLevels = [
  { name: "--", id: "" },
  { name: "Basic", id: "Basic" },
  { name: "Premium", id: "Premium" },
  { name: "Both", id: "Both" },
];

const statusList = [
  { name: "--", id: "" },
  { name: "Onboarding", id: "Onboarding" },
  { name: "Stalled", id: "Stalled" },
  { name: "Live", id: "Live" },
  { name: "Paused", id: "Paused" },
  { name: "Cancelling", id: "Cancelling" },
  { name: "Cancelled", id: "Cancelled" },
  { name: "Demo", id: "Demo" },
];

const ClientService = ({ entityDetails = null }) => {
  const { id } = useParams();

  const { protectPage, hasAccess } = usePermissions();

  protectPage(Permissions.Query.Client, {
    noAccessFallbackList: landlordNoAccessFallbackList,
    variables: { id },
  });

  const [campaignTypes] = useDataProvider("campaignTypes");

  const [clientTypes] = useDataProvider("clientTypes");

  const {
    state: formData,
    setState: setFormData,
    stateMethods,
    syncView,
  } = useHyperState("formData", {
    client_id: parseInt(id), // Int! // ????????

    client_type_id: 0, // Int
    service_level: "", // String
    status: "", // String
    contract_term_length: "", // Int

    campaign_type_id: 0, // Int
    verbally_agreed_date: "", // DateTime
    contract_signed_date: "", // DateTime
    data_received_date: "", // DateTime
    launch_date: "", // DateTime

    number_of_days_to_launch: "", // Int

    expected_users: "", // Int
    actual_users: "", // Int
    launch_number_user_diff: "", // Int

    contract_end_date: "", // DateTime
    final_bill_date: "", // DateTime
  });

  const {
    setFieldError,
    formHasErrors,
    fieldError,
    resolveMutationErrors,
    clearError,
  } = useFormErrors();

  const { setFieldValue: handleFieldOnChangeProxied } = useBaseFieldSetters(
    formData,
    stateMethods,
    clearError
  );

  const handleFieldOnChange = (e) => {
    clearError(e.target.name);
    if (e.target?.checkValidity && !e.target?.checkValidity()) {
      setFieldError(
        e.target.name,
        e.target.title || e.target.validationMessage || "Input has an error"
      );
      setDraft(false);
    } else if (formHasErrors()) {
      setDraft(false);
    } else {
      setDraft(true);
    }

    handleFieldOnChangeProxied(e);

    syncView();
    if (
      (formData.verbally_agreed_date !== "" ||
        formData.contract_signed_date !== "") &&
      formData.launch_date !== ""
    ) {
      let first = moment.min([
        moment(formData.verbally_agreed_date || undefined).startOf("day"),
        moment(formData.contract_signed_date || undefined).startOf("day"),
      ]);
      let launch = moment(formData.launch_date);
      handleFieldOnChangeProxied({
        target: {
          name: "number_of_days_to_launch",
          value: launch.diff(first, "days"),
        },
      });
    } else {
      handleFieldOnChangeProxied({
        target: {
          name: "number_of_days_to_launch",
          value: "",
        },
      });
    }
    syncView();
    if (formData.expected_users !== "" && formData.actual_users !== "") {
      handleFieldOnChangeProxied({
        target: {
          name: "launch_number_user_diff",
          value:
            parseInt(formData.actual_users) - parseInt(formData.expected_users),
        },
      });
    } else {
      handleFieldOnChangeProxied({
        target: {
          name: "launch_number_user_diff",
          value: "",
        },
      });
    }
  };

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const unformatDatetime = (datetime) => {
    if (!datetime) return "";
    return datetime;
  };

  const { loading, error } = useQuery(CLIENT_EXTENSION, {
    variables: {
      client_id: parseInt(id),
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.clientExtension) return false;
      let tempResult = { ...result.clientExtension };
      tempResult.verbally_agreed_date = unformatDatetime(
        tempResult.verbally_agreed_date
      );
      tempResult.contract_signed_date = unformatDatetime(
        tempResult.contract_signed_date
      );
      tempResult.data_received_date = unformatDatetime(
        tempResult.data_received_date
      );
      tempResult.launch_date = unformatDatetime(tempResult.launch_date);
      tempResult.contract_end_date = unformatDatetime(
        tempResult.contract_end_date
      );
      tempResult.final_bill_date = unformatDatetime(tempResult.final_bill_date);
      tempResult.service_level = !tempResult.service_level
        ? ""
        : tempResult.service_level;
      tempResult.status = !tempResult.status ? "" : tempResult.status;
      tempResult.client_type_id = !tempResult.client_type_id
        ? 0
        : tempResult.client_type_id;
      tempResult.campaign_type_id = !tempResult.campaign_type_id
        ? 0
        : tempResult.campaign_type_id;
      tempResult.contract_term_length = !tempResult.contract_term_length
        ? ""
        : tempResult.contract_term_length;
      tempResult.expected_users = !tempResult.expected_users
        ? ""
        : tempResult.expected_users;
      tempResult.actual_users = !tempResult.actual_users
        ? ""
        : tempResult.actual_users;
      tempResult.number_of_days_to_launch =
        tempResult.number_of_days_to_launch === null
          ? ""
          : tempResult.number_of_days_to_launch;
      tempResult.launch_number_user_diff =
        tempResult.launch_number_user_diff === null
          ? ""
          : tempResult.launch_number_user_diff;
      setFormData(tempResult);
      setDraft(false);
    },
  });

  const [update, { loading: isUpdating }] = useMutation(
    UPDATE_CLIENT_EXTENSION,
    {
      onCompleted: (result) => {
        displayNotification({
          heading: "Update OK",
          message: "Successfully updated parent primary data",
          secondsToDisplay: 2,
        });
        setDraft(false);
      },
      onError: (e) => {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to update",
          secondsToDisplay: 2,
        });
        resolveMutationErrors(e);
        setDraft(false);
      },
    }
  );

  const formatDatetime = (datetime) => {
    if (datetime && datetime !== "") {
      if (datetime.search("T") > -1)
        return datetime.split("T").join(" ") + ":00";
      return datetime;
    }
    return null;
  };

  const handleSave = () => {
    let data = { ...formData };
    data.verbally_agreed_date = formatDatetime(data.verbally_agreed_date);
    data.contract_signed_date = formatDatetime(data.contract_signed_date);
    data.data_received_date = formatDatetime(data.data_received_date);
    data.launch_date = formatDatetime(data.launch_date);
    data.contract_end_date = formatDatetime(data.contract_end_date);
    data.final_bill_date = formatDatetime(data.final_bill_date);
    data.service_level = data.service_level == "" ? "" : data.service_level;
    data.status = data.status == "" ? "" : data.status;
    data.client_type_id = data.client_type_id == 0 ? null : data.client_type_id;
    data.campaign_type_id =
      data.campaign_type_id == 0 ? null : data.campaign_type_id;
    data.contract_term_length =
      data.contract_term_length == "" ? null : data.contract_term_length;
    data.expected_users =
      data.expected_users == "" ? null : data.expected_users;
    data.actual_users = data.actual_users == "" ? null : data.actual_users;
    data.launch_number_user_diff =
      data.launch_number_user_diff == "" ? null : data.launch_number_user_diff;
    delete data.number_of_days_to_launch;
    update({ variables: data });
  };

  const [isDraft, setDraft] = useState(false);

  if (error) return <div>Error...</div>;

  if (!formData) return "";

  return (
    <PageContainer>
      <BlockContainer raised>
        <FormContainer>
          <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
            <Col width={12}>
              <Heading
                inset={true}
                text="Service information"
                description="Internal information for monitoring the lifecycle of clients."
              />
              <ClientOverview
                entityDetails={entityDetails}
                groupFilter={["activity", "users", "billing"]}
                dense
              />
            </Col>
          </Row>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="General information"
                      description="The status and service level may change but in most cases the others
                                   should have no reason to change once added."
                    />
                    <p className="text-gray-400 text-xs mt-4">
                      <i>Leave blank if not yet applicable to this client.</i>
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles="">
                    <SelectField
                      value={formData.status}
                      values={statusList}
                      label="Status"
                      name="status"
                      onChange={handleFieldOnChange}
                      valueKey="id"
                      labelKey="name"
                      error={fieldError("status")}
                      isRequired={false}
                      description="Update ASAP if things change"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <SelectField
                      value={formData.service_level}
                      values={serviceLevels}
                      label="Service level"
                      name="service_level"
                      onChange={handleFieldOnChange}
                      valueKey="id"
                      labelKey="name"
                      error={fieldError("service_level")}
                      isRequired={false}
                      description="Update ASAP if things change"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <SelectField
                      value={formData.client_type_id}
                      values={clientTypes}
                      label="Client type"
                      name="client_type_id"
                      onChange={handleFieldOnChange}
                      valueKey="id"
                      labelKey="name"
                      error={fieldError("client_type_id")}
                      isRequired={false}
                      prependOption="--"
                      description="Used in filters and reports"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <SelectField
                      value={formData.campaign_type_id}
                      values={campaignTypes}
                      label="Sales campaign"
                      name="campaign_type_id"
                      onChange={handleFieldOnChange}
                      valueKey="id"
                      labelKey="name"
                      error={fieldError("campaign_type_id")}
                      isRequired={false}
                      prependOption="--"
                      description="Ignore if unsure"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <DateTimeField
                      name="verbally_agreed_date"
                      label="Verbally agreed"
                      value={formData.verbally_agreed_date}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("verbally_agreed_date")}
                      isRequired={false}
                      description="Only if became a free client"
                      tweaks={`${
                        formData.verbally_agreed_date == "" ||
                        !formData.verbally_agreed_date
                          ? "opacity-50"
                          : ""
                      }`}
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <DateTimeField
                      name="contract_signed_date"
                      label="Contract signed"
                      value={formData.contract_signed_date}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("contract_signed_date")}
                      isRequired={false}
                      description="Only if became a paid client"
                      tweaks={`${
                        formData.contract_signed_date == "" ||
                        !formData.contract_signed_date
                          ? "opacity-50"
                          : ""
                      }`}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Onboarding"
                      description="Only update these while launching a client. Launch time and
                                   users difference are calculated automatically."
                    />
                    <p className="text-gray-400 text-xs mt-4">
                      <i>Leave blank if not yet applicable to this client.</i>
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles="">
                    <DateTimeField
                      name="data_received_date"
                      label="Data received"
                      value={formData.data_received_date}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("data_received_date")}
                      isRequired={false}
                      description="Initial data for launch"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <DateTimeField
                      name="launch_date"
                      label="Launched"
                      value={formData.launch_date}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("launch_date")}
                      isRequired={false}
                      description="End of onboarding"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <NumberField
                      loading={loading}
                      name="number_of_days_to_launch"
                      label="Launch time"
                      value={formData?.number_of_days_to_launch}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("number_of_days_to_launch")}
                      isRequired={false}
                      min={0}
                      max={99999}
                      step={1}
                      description="Onboarding time in days"
                      readOnly
                      disabled={
                        formData?.number_of_days_to_launch == "" ||
                        !formData?.number_of_days_to_launch
                      }
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <NumberField
                      loading={loading}
                      name="expected_users"
                      label="Expected user count"
                      value={formData?.expected_users}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("expected_users")}
                      isRequired={false}
                      min={0}
                      max={99999}
                      step={1}
                      description="Before launch only"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <NumberField
                      loading={loading}
                      name="actual_users"
                      label="Actual user count"
                      value={formData?.actual_users}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("actual_users")}
                      isRequired={false}
                      min={0}
                      max={99999}
                      step={1}
                      description="During launch only"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <NumberField
                      loading={loading}
                      name="launch_number_user_diff"
                      label="Launch users difference"
                      value={formData?.launch_number_user_diff}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("launch_number_user_diff")}
                      isRequired={false}
                      min={0}
                      max={99999}
                      step={1}
                      description="During launch only"
                      readOnly
                      disabled={
                        formData?.launch_number_user_diff == "" ||
                        !formData?.launch_number_user_diff
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="sborder-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Contract information"
                      description="This is only relevant if a contract exists. The contract end 
                                   date and final bill date are only needed if a client cancels."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  <Col width={4} extraStyles="">
                    <NumberField
                      loading={loading}
                      name="contract_term_length"
                      label="Contract term"
                      value={formData?.contract_term_length}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("contract_term_length")}
                      isRequired={false}
                      min={0}
                      max={99999}
                      step={1}
                      description="Contract term in months"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <DateTimeField
                      name="contract_end_date"
                      label="Contract end date"
                      value={formData.contract_end_date}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("contract_end_date")}
                      isRequired={false}
                      description="Cancellations only"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <DateTimeField
                      name="final_bill_date"
                      label="Final bill date"
                      value={formData.final_bill_date}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("final_bill_date")}
                      isRequired={false}
                      description="Cancellations only"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormData>
          <FormControls spaced={false}>
            {hasAccess(Permissions.Mutation.UpdateClient) && (
              <SaveButton
                onClick={handleSave}
                disabled={!isDraft || formHasErrors()}
                loading={isUpdating}
              />
            )}
          </FormControls>
        </FormContainer>
      </BlockContainer>
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default ClientService;
