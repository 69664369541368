import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { createInvoiceMutation } from "../../graphql/mutations/billingMutations";
import {
  BILLING_MANUAL_INVOICE_STATUS,
  INVOICE_TYPE_MODEL_NAME,
  PAYMENT_SYSTEMS,
} from "./../../configs/constants";
import useBaseNavigation from "./../../hooks/baseNavigation";
import useHyperState from "./../../hooks/hyperState";
import useFormErrors from "./../../hooks/formErrors";
import useBaseFieldSetters from "./../../hooks/baseFieldSetters";
import useDataProvider from "./../../hooks/dataProvider";
import PageSpinner from "./../../mood-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "./../../mood-ui/Layout";
import {
  FormControls,
  SaveButton,
  CancelButton,
} from "./../../mood-ui/Controls";
import { Heading } from "./../../mood-ui/Typography";
import {
  NumberField,
  DateField,
  SelectField,
  TextField,
} from "./../../mood-ui/Fields";

const CREATE_INVOICE = gql(createInvoiceMutation);

const OPTIONS = {
  USER_MODE: "",
};

const VabooUserInvoiceCreate = () => {
  useEffect(() => {
    OPTIONS.USER_MODE = window.location.pathname.includes("clients")
      ? "clients"
      : "users";
    return () => {
      OPTIONS.USER_MODE = "";
    };
  }, []);
  const {
    setFieldError,
    formHasErrors,
    fieldError,
    resolveMutationErrors,
    clearError,
  } = useFormErrors();
  const { id: user_id } = useParams();
  const [users] = useDataProvider("landlords");
  const { goBack } = useBaseNavigation(INVOICE_TYPE_MODEL_NAME, {
    urlContext: `${OPTIONS.USER_MODE}/${user_id}`,
  });

  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    cart_id: user_id,
    amount: "",
    description: "",
    payment_system: "Stripe",
    date_to_charge: undefined,
  });

  const {
    setFieldValue: handleFieldOnChangeProxied,
    toggleCheckbox: handleToggleCheckbox,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const handleFieldOnChange = (e) => {
    clearError(e.target.name);
    if (e.target?.checkValidity && !e.target?.checkValidity()) {
      setFieldError(
        e.target.name,
        e.target.title || e.target.validationMessage || "Input has an error"
      );
      setDraft(false);
    } else if (formHasErrors()) {
      setDraft(false);
    } else {
      setDraft(true);
    }
    handleFieldOnChangeProxied(e);
  };

  const [save, { data: saveResult, loading: isSaving, error: saveError }] =
    useMutation(CREATE_INVOICE, {
      variables: { ...formData },
      onCompleted: (result) => {
        goBack();
      },
      onError: (e) => {
        resolveMutationErrors(e);
      },
    });

  const handleSave = () => {
    const data = { ...formData };
    save({
      variables: {
        ...data,
        cart_id: parseInt(data.cart_id),
        amount: parseFloat(data.amount),
      },
    });
  };

  const [isDraft, setDraft] = useState(false);

  if (!formData) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Create a new invoice"
              description="Create a new invoice to be paid for this account."
            />
          </Col>
        </Row>
        <FormContainer>
          <FormData>
            <Row tweaks="border-[#dfe2e8] py-8 mx-5">
              <Col width={3}>
                <Row tweaks="">
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Invoice details"
                      description="Customise the invoice settings. Once saved, this invoice cannot be changed."
                    />
                  </Col>
                  <Col width={12}>
                    {/* {OPTIONS.USER_MODE === "users" && (
                      <ReadOnlyField
                        label="Assign to"
                        value="Invoice will be assigned to the current user"
                      />
                    )} */}
                    {OPTIONS.USER_MODE === "clients" && (
                      <SelectField
                        value={formData.cart_id}
                        values={users}
                        label="Assign to"
                        name="cart_id"
                        onChange={handleFieldOnChange}
                        valueKey="id"
                        labelKey="email"
                        isRequired={true}
                        description="Select which client to invoice"
                      />
                    )}
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row tweaks="gap-y-2">
                  <Col width={4}>
                    <NumberField
                      name="amount"
                      label="Amount"
                      value={formData.amount}
                      onChange={handleFieldOnChange}
                      error={fieldError("amount")}
                      isRequired={true}
                      min={0.01}
                      step={0.01}
                      gbp
                      description="Must include VAT"
                    />
                  </Col>
                  <Col width={4}>
                    <DateField
                      name="date_to_charge"
                      label="Due date"
                      value={formData.date_to_charge}
                      description="Leave empty to charge now"
                      onChange={handleFieldOnChange}
                      error={fieldError("date_to_charge")}
                    />
                  </Col>
                  <Col width={4}>
                    <SelectField
                      value={formData.payment_system}
                      values={PAYMENT_SYSTEMS}
                      label="Payment method"
                      name="payment_system"
                      description="Select a payment method"
                      onChange={handleFieldOnChange}
                      valueKey="id"
                      labelKey="name"
                      isRequired={true}
                    />
                  </Col>
                  <Col width={12}>
                    <TextField
                      name="description"
                      label="Description"
                      value={formData.description}
                      onChange={handleFieldOnChange}
                      error={fieldError("description")}
                      minlength={12}
                      maxlength={164}
                      isRequired={true}
                      description="Shown on the invoice seen by the account owner"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormData>
          <FormControls>
            <CancelButton onClick={goBack} />
            <SaveButton
              onClick={handleSave}
              disabled={!isDraft || formHasErrors()}
              loading={isSaving}
            />
          </FormControls>
        </FormContainer>
      </BlockContainer>
    </PageContainer>
  );
};

export default VabooUserInvoiceCreate;
