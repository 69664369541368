import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { customLinkDetails } from "./../../graphql/queries/landlordQueries";
import { updateCustomLinkMutation } from "./../../graphql/mutations/landlordMutations";
import useBaseNavigation from "./../../hooks/baseNavigation";
import useBaseFieldSetters from "./../../hooks/baseFieldSetters";
import useFormErrors from "./../../hooks/formErrors";
import useHyperState from "./../../hooks/hyperState";
import { CUSTOM_LINK_TYPE_MODEL_NAME } from "./../../configs/constants";
import PageSpinner from "./../../mood-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "./../../mood-ui/Layout";
import {
  FormControls,
  SaveButton,
  CancelButton,
} from "./../../mood-ui/Controls";
import { Heading } from "./../../mood-ui/Typography";

import {
  TextField,
  NumberField,
  ToggleCheckboxField,
} from "./../../mood-ui/Fields";

import { LabelContext } from "../../contexts/LabelContext";

const CUSTOM_LINK_DETAILS = gql(customLinkDetails);
const UPDATE_CUSTOM_LINK = gql(updateCustomLinkMutation);

const LandlordCustomLink = () => {
  const {
    setFieldError,
    formHasErrors,
    fieldError,
    resolveMutationErrors,
    clearError,
  } = useFormErrors();
  const { id: landlordId, clId: id } = useParams();
  const labelContext = useContext(LabelContext);

  const { goBack } = useBaseNavigation(CUSTOM_LINK_TYPE_MODEL_NAME, {
    urlContext: `clients/${landlordId}`,
  });

  const {
    state: formData,
    setState: setFormData,
    stateMethods,
    syncView,
  } = useHyperState("formData", {
    text: "",
    color: "",
    icon: "",
    href: "",
    external: false,
    display_order: 0,
    labels: [],
  });

  const {
    setFieldValue: handleFieldOnChangeProxied,
    toggleCheckbox: handleToggleCheckbox,
    toggleArrayFieldValue: toggleListValue,
    arrayFieldHasValue: listInclude,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const handleFieldOnChange = (e) => {
    clearError(e.target.name);
    if (e.target?.checkValidity && !e.target?.checkValidity()) {
      setFieldError(
        e.target.name,
        e.target.title || e.target.validationMessage || "Input has an error"
      );
      setDraft(false);
    } else if (formHasErrors()) {
      setDraft(false);
    } else {
      setDraft(true);
    }
    syncView();
    handleFieldOnChangeProxied(e);
  };

  const {
    // data,
    loading,
    error,
  } = useQuery(CUSTOM_LINK_DETAILS, {
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.customLink) return false;
      setFormData({
        ...result.customLink,
        labels: result.customLink.labels.map((l) => l.id),
      });
    },
    onError: (e) => {
      console.log("e", e);
    },
  });

  const [updateCustomLink, { loading: isUpdating }] = useMutation(
    UPDATE_CUSTOM_LINK,
    {
      variables: { ...formData },
      onCompleted: (result) => {
        goBack();
      },
      onError: (e) => {
        resolveMutationErrors(e);
      },
    }
  );

  const handleSave = () => {
    const data = { ...formData };
    updateCustomLink({
      variables: { ...data, labels: data.labels.map((l) => parseInt(l)) },
    });
  };

  const [isDraft, setDraft] = useState(false);

  if (error) return <div>Error...</div>;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Update custom link"
              description="Custom links are shown in the side menu of the member platform."
            />
          </Col>
        </Row>
        <FormContainer>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Link settings"
                      description="Control the appearance and behaviour of this link. Use text or an icon or both."
                    />
                    <p className="text-xs text-[#592373] opacity-60 underline underline-offset-4 mt-2 mb-7 select-none">
                      <a
                        href={`https://pictogrammers.com/library/mdi/`}
                        rel="noreferrer"
                        target="_BLANK"
                      >
                        Browse icon list
                      </a>
                    </p>
                    <button
                      style={{ background: formData?.color, color: "white" }}
                      className={`rounded mt-4 justify-center py-2 px-3 border border-transparent text-sm leading-2 pointer-events-none flex gap-x-2`}
                    >
                      {formData?.icon && formData?.icon !== "" && (
                        <span
                          className={`text-[17px] mdi ${formData?.icon}`}
                        ></span>
                      )}
                      {formData?.text}
                    </button>
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row tweaks="gap-y-3">
                  <Col width={4} extraStyles="">
                    <TextField
                      loading={!formData || loading}
                      name="text"
                      label="Button text"
                      value={formData?.text}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("text")}
                      maxlength="24"
                      description="Keep as short as possible"
                    />
                  </Col>
                  <Col width={3} extraStyles="">
                    <TextField
                      loading={!formData || loading}
                      name="icon"
                      label="Button icon"
                      value={formData?.icon}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("icon")}
                      minlength="5"
                      maxlength="42"
                      pattern="^(mdi-)[a-z0-9](?:[a-z0-9\-]{0,61}[a-z0-9])?"
                      description="Use 'mdi-' + 'icon_name'"
                    />
                  </Col>
                  <Col width={3} extraStyles="">
                    <TextField
                      loading={!formData || loading}
                      name="color"
                      label="Button colour"
                      value={formData?.color}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("color")}
                      isRequired={true}
                      minlength="3"
                      maxlength="24"
                      pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$|[a-z]{3,24}?"
                      description="Colour name or hex code"
                    />
                  </Col>
                  <Col width={2} extraStyles="">
                    <NumberField
                      loading={!formData || loading}
                      name="display_order"
                      label="List order"
                      value={formData?.display_order}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("display_order")}
                      isRequired={true}
                      min={1}
                      max={64}
                      description="Position in list"
                    />
                  </Col>
                  <Col width={9} extraStyles="">
                    <TextField
                      loading={!formData || loading}
                      name="href"
                      label="Link url"
                      value={formData?.href}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("href")}
                      isRequired={true}
                      maxlength="1000"
                      pattern={`^(tel:|TEL:)(\\+)?([0-9]{9,16})|^(mailto:|MAILTO:)([a-zA-Z0-9-_\.]+)@([a-zA-Z0-9-_\.]+)\\.([a-zA-Z]{2,8})(\.[a-zA-Z]{2,8})?|^(https://|HTTPS://)([a-zA-Z0-9-_]+)\\.([\\[\\])\(\#\!\*\+\^\$\'\"\%\@\?\/\=\&\.a-zA-Z0-9-_]{2,1000})|^[\/]([a-zA-Z0-9-_]+)(\/[a-zA-Z-_0-9]+)*$`}
                      description="/reward-cards, https://external.com, mailto:you@me.co, tel:+447~"
                    />
                  </Col>
                  <Col width={3}>
                    <ToggleCheckboxField
                      loading={!formData || loading}
                      id="external"
                      name="external"
                      label="External"
                      description="Open in a new tab"
                      isChecked={formData?.external}
                      onChange={(e) => {
                        handleToggleCheckbox(e);
                        setDraft(true);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row tweaks="border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Link visibility"
                      description="Controls who can see this link in the member platform."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row tweaks="gap-y-3">
                  <Col width={12} extraStyles="">
                    {!formData ? (
                      <PageSpinner inline extraStyles="h-[300px]" />
                    ) : (
                      labelContext?.labels &&
                      labelContext?.labels?.length && (
                        <Row tweaks="gap-0 sm:grid-cols-5 py-1 px-px">
                          {labelContext.labels.map((label) => (
                            <Col
                              key={label.id}
                              extraStyles="capitalize sm:col-span-1"
                            >
                              <ToggleCheckboxField
                                loading={!formData || loading}
                                id={label.id}
                                name="labels"
                                label={label.name}
                                isChecked={listInclude("labels", label.id)}
                                onChange={() => {
                                  toggleListValue("labels", label.id);
                                  setDraft(true);
                                }}
                                flush
                                description="Enable for type"
                              />
                            </Col>
                          ))}
                        </Row>
                      )
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormData>
          <FormControls>
            <CancelButton onClick={goBack} />
            <SaveButton
              onClick={handleSave}
              disabled={!isDraft || formHasErrors()}
              loading={isUpdating}
            />
          </FormControls>
        </FormContainer>
      </BlockContainer>
    </PageContainer>
  );
};

export default LandlordCustomLink;
