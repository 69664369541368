import React from "react";
import moment from "moment";
import { useParams, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { invoicesReport } from "./../../graphql/queries/reportQueries";
import { generateSystemAccessCodeMutation } from "./../../graphql/mutations/systemMutations";
import Permissions from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import useBaseNavigation from "./../../hooks/baseNavigation";
import useTypeFilters from "./../../hooks/typeFilters";
import {
  REPORTS_INVOICES_MODEL_NAME,
  VABOO_REST_API_URL,
} from "./../../configs/constants";
import PageSpinner from "./../../moose-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
} from "./../../moose-ui/Layout";
import { StdButton } from "./../../moose-ui/Controls";
import { Heading, ItemsNotFound } from "./../../moose-ui/Typography";
import Search from "./../../moose-ui/Search";
import TypeFilters from "./../../moose-ui/filters/TypeFilters";
import DateRangeFilter from "./../../moose-ui/filters/DateRangeFilter";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
  TableSelectedActions,
  TableActions,
  Pagination,
} from "./../../moose-ui/DataTable";
import useItemCounter from "../../hooks/itemCounter";

const INVOICES_REPORT = gql(invoicesReport);
const GENERATE_SYSTEM_ACCESS_CODE = gql(generateSystemAccessCodeMutation);

const START_DATE = moment("2018-01-01").format("YYYY-MM-DD");
const END_DATE = moment().format("YYYY-MM-DD");

const Invoices = () => {
  const { protectPage } = usePermissions();
  protectPage(Permissions.Query.InvoiceReport);
  const { page, limit, orderBy, orderHow, search } = useParams();
  const { search: queryString } = useLocation();
  const { data, loading, error } = useQuery(INVOICES_REPORT, {
    variables: {
      page: parseInt(page),
      limit: parseInt(limit),
      orderBy,
      orderHow,
      search,
      queryString,
    },
    fetchPolicy: "cache-and-network",
  });

  const { itemsTotal } = useItemCounter(data, { searchKey: "invoiceReport" });

  const {
    nextPage,
    prevPage,
    applySearch,
    applyFilters,
    resetFilters,
    queryParams,
  } = useBaseNavigation(REPORTS_INVOICES_MODEL_NAME, {
    queryDefaults: { startDate: [START_DATE], endDate: [END_DATE] },
    showingItemsCount: itemsTotal,
  });

  const {
    data: typeFilters,
    selectedFilters,
    hasActiveFilters,
    updateFilter: onUpdateFilter,
    resetFilters: onResetFilters,
    removeFilterValue: onRemoveFilterValue,
    applyFilters: onApplyFilters,
    isReady: typeFiltersReady,
  } = useTypeFilters({
    filtersFor: "invoice",
    onApply: applyFilters,
    onReset: resetFilters,
    queryString,
  });

  const [generateSystemAccessCode] = useMutation(GENERATE_SYSTEM_ACCESS_CODE, {
    onCompleted: (result) => {
      const restApiExportUrl =
        queryString.length > 0
          ? `${VABOO_REST_API_URL}reporting/invoice-report${queryString}&system_access_code=${result.generateSystemAccessCode.code}`
          : `${VABOO_REST_API_URL}reporting/invoice-report?system_access_code=${result.generateSystemAccessCode.code}`;
      window.open(restApiExportUrl);
    },
    onError: (e) => {
      alert("Failed to generate Vaboo REST System Access Code");
      console.log(e);
    },
  });

  if (error) return <div>Error</div>;
  if (!data || !typeFiltersReady) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer>
        {loading && <Heading text="Report. Invoices. Loading..." />}
        {!loading && <Heading text="Report. Invoices" />}
        <Row>
          <Col width={12}>
            <TypeFilters
              data={typeFilters}
              onApply={onApplyFilters}
              onReset={onResetFilters}
              onRemoveValue={onRemoveFilterValue}
              hasActiveFilters={hasActiveFilters}
              onUpdate={onUpdateFilter}
              selectedFilters={selectedFilters}
            />
            <DateRangeFilter
              startDate={queryParams.startDate[0]}
              endDate={queryParams.endDate[0]}
              onApplyRange={applyFilters}
            />
          </Col>
        </Row>
        <Row>
          <Col width={9}>
            <StdButton
              label="Export to CSV"
              onClick={generateSystemAccessCode}
            />
          </Col>
          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row>

        {data && data.invoiceReport && data.invoiceReport.length > 0 && (
          <TableContainer>
            <TableView>
              <THeading>
                <TRow>
                  <THCol name="id" value="ID" />
                  <THCol name="client_id" value="Client ID" />
                  <THCol name="account_type" value="Account Type" />
                  <THCol name="firstname" value="Firstname" />
                  <THCol name="lastname" value="Lastname" />
                  <THCol name="email" value="Email" />
                  <THCol name="account_name" value="Account Name" />
                  <THCol name="amount" value="Amount" />
                  <THCol name="description" value="Description" />
                  <THCol name="status" value="Status" />
                  <THCol name="created_at" value="Created At" />
                  <THCol name="updated_at" value="Updated At" />
                  <THCol
                    name="last_billing_attempt_date"
                    value="Last Billing Attempt Date"
                  />
                  <THCol name="payment_system" value="Payment System" />
                  <THCol name="pricing_model" value="Pricing Model" />
                </TRow>
              </THeading>
              <TContent>
                {data.invoiceReport.map((invoice, idx) => (
                  <TRow key={idx}>
                    <TCol value={invoice.id} />
                    <TCol value={invoice.owner.id} />
                    <TCol value={invoice.owner.account_type} />
                    <TCol value={invoice.owner.firstname} />
                    <TCol value={invoice.owner.lastname} />
                    <TCol value={invoice.owner.email} />
                    <TCol value={invoice.owner.account_name} />
                    <TCol value={invoice.amount} />
                    <TCol value={invoice.description} />
                    <TCol value={invoice.status} />
                    <TCol value={invoice.created_at} />
                    <TCol value={invoice.updated_at} />
                    <TCol value={invoice.last_billing_attempt_date} />
                    <TCol value={invoice.payment_system} />
                    <TCol value={invoice.pricing_model} />
                  </TRow>
                ))}
              </TContent>
            </TableView>
            <TableActions>
              <TableSelectedActions></TableSelectedActions>
              <Pagination onPrev={prevPage} onNext={nextPage} />
            </TableActions>
          </TableContainer>
        )}
        {data && data.invoiceReport && data.invoiceReport.length == 0 && (
          <ItemsNotFound text="Invoices not found" />
        )}
      </BlockContainer>
    </PageContainer>
  );
};

export default Invoices;
