import { useState } from "react";

const useListSelection = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const toggleRow = (id) => {
    let newSelectedRows = [...selectedRows];
    if (selectedRows.indexOf(id) > -1) {
      newSelectedRows = selectedRows.filter(
        (row) => parseInt(row) != parseInt(id)
      );
    } else {
      newSelectedRows.push(parseInt(id));
    }
    setSelectedRows(newSelectedRows);
  };

  const isRowSelected = (id) => {
    return selectedRows.indexOf(parseInt(id)) > -1 ? true : false;
  };

  const isAllRowsSelected = (rows) => {
    let isActive = true;
    if (selectedRows.length === 0) {
      isActive = false;
    } else {
      rows.forEach((id) => {
        if (selectedRows.indexOf(id) < 0) isActive = false;
      });
    }
    return isActive;
  };

  const toggleAllRows = (rows) => {
    if (isAllRowsSelected(rows)) {
      setSelectedRows([]);
    } else {
      const newRows = rows.map((r) => parseInt(r));
      setSelectedRows(newRows);
    }
  };

  const clearSelection = () => {
    setSelectedRows([]);
  };

  return {
    selectedRows,
    toggleRow,
    isRowSelected,
    clearSelection,
    isAllRowsSelected,
    toggleAllRows,
  };
};

export default useListSelection;
