export const allInvoices = `query invoices($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
    invoices(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
        id    
        amount
        description
        status
        last_billing_attempt_date
        post_billing_message
        payment_system
        created_at
      }
    }
    `;

export const invoiceDetails = `query invoice($id: ID!) {  
      invoice(id: $id) {
          id    
          cart_id
          amount
          description
          status
          last_billing_attempt_date
          post_billing_message
          payment_system
          date_to_charge
          created_at 
          checks {
            id
            status
            post_billing_message
            created_at
          }
        }
      }
      `;

export const allMembershipVouchers = `query membershipVouchers($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
        membershipVouchers(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
            id
            type    
            title
            code
            max_uses_per_user
            released
            used
            status
            expires_at
          }
        }
        `;

export const membershipVoucherDetails = `query membershipVoucher($id: ID!) {  
  membershipVoucher(id: $id) {
    id   
    title
    code
    description
    type
    max_uses_per_user
    released
    used
    monthly_value
    annually_value
    lifetime_value
    status
    expires_at
            }
          }
          `;

export const clientPricingModel = `query clientPricingModel($client_id: Int!) {  
  clientPricingModel(client_id: $client_id) {
    name                
  }
}
`;

export const clientPackagePricing = `query clientPackagePricing($client_id: Int!) {  
  clientPackagePricing(client_id: $client_id) {
    units
    price
  }
}
`;

export const clientSignupPricing = `query clientSignupPricing($client_id: Int!, $plan: String!) {  
  clientSignupPricing(client_id: $client_id, plan: $plan) {
    units
    price
  }
}
`;

export const clientGraduatedPricing = `query clientGraduatedPricing($client_id: Int!) {  
  clientGraduatedPricing(client_id: $client_id) {    
    tiers {
      units_start_range
      units_end_range
      price
    }
  }
}
`;

export const clientVolumePricing = `query clientVolumePricing($client_id: Int!) {  
  clientVolumePricing(client_id: $client_id) {
    tiers {
      units_start_range
      units_end_range
      price
    }
  }
}
`;

export const clientVolumePackagePricing = `query clientVolumePackagePricing($client_id: Int!) {  
  clientVolumePackagePricing(client_id: $client_id) {
    tiers {
      units_start_range
      units_end_range
      price
    }
  }
}
`;

export const clientPaymentSettings = `query clientPaymentSettings($client_id: Int!) {  
  clientPaymentSettings(client_id: $client_id) {
      account_id
      type
      maintance_fee_monthly_amount
      maintance_fee_monthly_billing_day
      gifted_premium_access_fee_monthly
      acquired_premium_access_fee_monthly
      minimum_payment_amount
      number_of_free_premium_users
      can_give_basic
      can_give_premium
      can_upgrade
      must_have_payment_method
      payment_takeover_by_user_id
      invoice_to_text
      only_charge_for_active_premium
      should_invoice
      payment_method_code
      available_payment_methods {
        id
        code
        name
      }
      agency_signup_model
    }
  }
  `;

export const calculateClientStandardPricing = `query calculateClientStandardPricing(
    $client_id: Int!,
    $maintance_fee_monthly_amount: Float,
    $gifted_premium_access_fee_monthly: Float,
    $minimum_payment_amount: Float,
    $number_of_free_premium_users: Int,
    $number_of_premium_users: Int
  ) {  
  calculateClientStandardPricing(
    client_id: $client_id,
    maintance_fee_monthly_amount: $maintance_fee_monthly_amount,
    gifted_premium_access_fee_monthly: $gifted_premium_access_fee_monthly,
    minimum_payment_amount: $minimum_payment_amount,
    number_of_free_premium_users: $number_of_free_premium_users,
    number_of_premium_users: $number_of_premium_users
  ) {
      amount      
    }
  }
  `;

export const calculateClientPackagePricing = `query calculateClientPackagePricing(
    $client_id: Int!,
    $maintance_fee_monthly_amount: Float,
    $gifted_premium_access_fee_monthly: Float,
    $minimum_payment_amount: Float,
    $number_of_free_premium_users: Int,
    $number_of_premium_users: Int,
    $units: Int,
    $price: Float
  ) {  
    calculateClientPackagePricing(
      client_id: $client_id,
      maintance_fee_monthly_amount: $maintance_fee_monthly_amount,
      gifted_premium_access_fee_monthly: $gifted_premium_access_fee_monthly,
      minimum_payment_amount: $minimum_payment_amount,
      number_of_free_premium_users: $number_of_free_premium_users,
      number_of_premium_users: $number_of_premium_users,
      units: $units,
      price: $price
  ) {
      amount      
    }
  }
  `;

export const calculateClientGraduatedPricing = `query calculateClientGraduatedPricing(
    $client_id: Int!,
    $maintance_fee_monthly_amount: Float,
    $gifted_premium_access_fee_monthly: Float,
    $minimum_payment_amount: Float,
    $number_of_free_premium_users: Int,
    $number_of_premium_users: Int,
    $tiers: [PricingTierInput]
  ) {  
    calculateClientGraduatedPricing(
      client_id: $client_id,
      maintance_fee_monthly_amount: $maintance_fee_monthly_amount,
      gifted_premium_access_fee_monthly: $gifted_premium_access_fee_monthly,
      minimum_payment_amount: $minimum_payment_amount,
      number_of_free_premium_users: $number_of_free_premium_users,
      number_of_premium_users: $number_of_premium_users,
      tiers: $tiers
  ) {
      amount      
    }
  }
  `;

export const calculateClientVolumePricing = `query calculateClientVolumePricing(
    $client_id: Int!,
    $maintance_fee_monthly_amount: Float,
    $gifted_premium_access_fee_monthly: Float,
    $minimum_payment_amount: Float,
    $number_of_free_premium_users: Int,
    $number_of_premium_users: Int,
    $tiers: [PricingTierInput]
  ) {  
    calculateClientVolumePricing(
      client_id: $client_id,
      maintance_fee_monthly_amount: $maintance_fee_monthly_amount,
      gifted_premium_access_fee_monthly: $gifted_premium_access_fee_monthly,
      minimum_payment_amount: $minimum_payment_amount,
      number_of_free_premium_users: $number_of_free_premium_users,
      number_of_premium_users: $number_of_premium_users,
      tiers: $tiers
  ) {
      amount      
    }
  }
  `;

export const calculateClientVolumePackagePricing = `query calculateClientVolumePackagePricing(
    $client_id: Int!,
    $maintance_fee_monthly_amount: Float,
    $gifted_premium_access_fee_monthly: Float,
    $minimum_payment_amount: Float,
    $number_of_free_premium_users: Int,
    $number_of_premium_users: Int,
    $tiers: [PricingTierInput]
  ) {  
    calculateClientVolumePackagePricing(
      client_id: $client_id,
      maintance_fee_monthly_amount: $maintance_fee_monthly_amount,
      gifted_premium_access_fee_monthly: $gifted_premium_access_fee_monthly,
      minimum_payment_amount: $minimum_payment_amount,
      number_of_free_premium_users: $number_of_free_premium_users,
      number_of_premium_users: $number_of_premium_users,
      tiers: $tiers
  ) {
      amount      
    }
  }
  `;

export const membershipPricing = `query membershipPricing($client_id: Int!) {  
    membershipPricing(client_id: $client_id) {
        id    
        client_id
        monthly_price
        annual_price
        lifetime_price
        use_global_settings
      }
    }
    `;
