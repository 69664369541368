import { InMemoryCache } from "apollo-cache-inmemory";

export const APP_DEFAULT_LIST_PARAMS = "1/50/0/0/0";

export const TAG_MODEL_NAME = "tags";
export const PIPELINE_MODEL_NAME = "pipelines";
export const OFFER_MODEL_NAME = "offers";
export const TILLO_OFFER_MODEL_NAME = "cards";
export const MERCHANT_MODEL_NAME = "merchants";
export const CLIENT_MODEL_NAME = "clients";
export const CONTENT_MODEL_NAME = "content";
export const TENANT_MODEL_NAME = "users";
export const FILE_GALLERY_MODEL_NAME = "images";
export const QUESTION_MODEL_NAME = "questions";
export const SURVEY_MODEL_NAME = "surveys";
export const SECTION_MODEL_NAME = "sections";
export const CINEMA_CHAIN_MODEL_NAME = "cinema-chains";
export const CINEMA_VENUE_MODEL_NAME = "cinema-venues";
export const CINEMA_PRODUCT_MODEL_NAME = "cinema-products";
export const MEMBERSHIP_VOUCHER_MODEL_NAME = "membership-vouchers";
export const ROLE_MODEL_NAME = "roles";
export const TERM_MODEL_NAME = "terms";
export const SYSTEM_USER_MODEL_NAME = "control-application-users";
export const SURVEY_TYPE_MODEL_NAME = "survey_types";
export const CUSTOM_LINK_TYPE_MODEL_NAME = "custom-links";
export const DIRECT_REGISTER_LINK_TYPE_MODEL_NAME = "direct-register-links";
export const INVOICE_TYPE_MODEL_NAME = "invoices";
export const USER_LOGIN_TYPE_MODEL_NAME = "logins";
export const BENEFIT_LOG_TYPE_MODEL_NAME = "benefit-logs";
export const TENANT_ACTIVITY_EVENT_MODEL_NAME = "activity-events";
export const IMPORT_TYPE_MODEL_NAME = "imports";
export const BULK_OPERATION_TYPE_MODEL_NAME = "bulk-operations";
export const REPORT_MODEL_NAME = "reports";

export const REPORTS_TENANT_QA_FLOW_MODEL_NAME = "reports/tenant-qa-flow";
export const REPORTS_USER_ORDER_MODEL_NAME = "reports/user-orders";
export const REPORTS_USER_CINEMA_ORDERS_MODEL_NAME =
  "reports/cinema-society-orders";
export const REPORTS_PASSPORTS_MODEL_NAME = "reports/passports";
export const REPORTS_ACTIVITY_EVENTS_MODEL_NAME = "reports/activity-events";
export const REPORTS_QA_STATS_MODEL_NAME = "reports/tenant-qa-stats";
export const REPORTS_INVOICES_MODEL_NAME = "reports/invoices";

export const REPORTS_PROFILE_COMPLETIONS_MODEL_NAME =
  "/reports/profile-field-completions";
export const REPORTS_PROFILE_FIELD_ANSWERS_MODEL_NAME =
  "/reports/profile-field-answers";

export const LANDLORD_LABEL_ID = 1;
export const STAFF_LABEL_ID = 2;
export const TENANT_LABEL_ID = 3;
export const VENDOR_LABEL_ID = 4;
export const BUYER_LABEL_ID = 5;
export const STUDENT_LABEL_ID = 6;
export const HOME_OWNER_LABEL_ID = 7;
export const OTHER_LABEL_ID = 8;
export const DEFAULT_LABEL_ID = TENANT_LABEL_ID;

export const REGULAR_TAG_TYPE_ID = 1;
export const EXCLUSION_TAG_TYPE_ID = 2;
export const DEFAULT_TAG_TYPE_ID = REGULAR_TAG_TYPE_ID;

export const ACCOUNTING_STANDARD_PRICING = "STANDARD_PRICING";
export const ACCOUNTING_PACKAGE_PRICING = "PACKAGE_PRICING";
export const ACCOUNTING_GRADUATED_PRICING = "GRADUATED_PRICING";
export const ACCOUNTING_VOLUME_PRICING = "VOLUME_PRICING";
export const ACCOUNTING_VOLUME_PACKAGE_PRICING = "VOLUME_PACKAGE_PRICING";

export const ACCOUNTING_VABOO_SELF_SERVE = "VABOO_SELF_SERVE";
export const ACCOUNTING_THE_GOOD_AGENT = "THE_GOOD_AGENT";
export const ACCOUNTING_THE_CARING_AGENT = "THE_CARING_AGENT";
export const ACCOUNTING_THE_GREAT_AGENT = "THE_GREAT_AGENT";

export const ACCOUNTING_EMPLOYEE_PRICING = "EMPLOYEE_PRICING";
export const ACCOUNTING_FINANCE_PRICING = "FINANCE_PRICING";

export const PRICING_MODELS = [
  { id: ACCOUNTING_STANDARD_PRICING, name: ACCOUNTING_STANDARD_PRICING },
  { id: ACCOUNTING_PACKAGE_PRICING, name: ACCOUNTING_PACKAGE_PRICING },
  { id: ACCOUNTING_GRADUATED_PRICING, name: ACCOUNTING_GRADUATED_PRICING },
  { id: ACCOUNTING_VOLUME_PRICING, name: ACCOUNTING_VOLUME_PRICING },
  {
    id: ACCOUNTING_VOLUME_PACKAGE_PRICING,
    name: ACCOUNTING_VOLUME_PACKAGE_PRICING,
  },
  { id: ACCOUNTING_VABOO_SELF_SERVE, name: ACCOUNTING_VABOO_SELF_SERVE },
  { id: ACCOUNTING_THE_GOOD_AGENT, name: ACCOUNTING_THE_GOOD_AGENT },
  { id: ACCOUNTING_THE_CARING_AGENT, name: ACCOUNTING_THE_CARING_AGENT },
  { id: ACCOUNTING_THE_GREAT_AGENT, name: ACCOUNTING_THE_GREAT_AGENT },
  { id: ACCOUNTING_EMPLOYEE_PRICING, name: ACCOUNTING_EMPLOYEE_PRICING },
  { id: ACCOUNTING_FINANCE_PRICING, name: ACCOUNTING_FINANCE_PRICING },
];

export const DEFAULT_LIST_ROUTE_PARAMS_W_ZERO_LIMIT = {
  page: 1,
  limit: 0,
  orderBy: "name",
  orderHow: "asc",
  search: "0",
  queryString: "",
};

export const DEFAULT_LIST_ROUTE_PARAMS_W_ZERO_LIMIT_ID_ORDER = {
  page: 1,
  limit: 0,
  orderBy: "id",
  orderHow: "asc",
  search: "0",
  queryString: "",
};

export const DEFAULT_LIST_ROUTE_PARAMS_W_ZERO_LIMIT_EMAIL_ORDER = {
  page: 1,
  limit: 0,
  orderBy: "email",
  orderHow: "asc",
  search: "0",
  queryString: "",
};

export const BILLING_TYPES = [
  {
    id: "Monthly",
    name: "Monthly",
  },
];

export const OFFER_STATUSES = [
  {
    id: "Inactive",
    name: "Inactive",
  },
  {
    id: "Active",
    name: "Active",
  },
];

export const QUESTION_TYPES = [
  {
    id: "MULTIPLE",
    name: "Multiple",
  },
  {
    id: "MULTIPLE_AND_CUSTOM",
    name: "Multiple and custom",
  },
  {
    id: "SINGLE",
    name: "Single",
  },
  {
    id: "SINGLE_AND_CUSTOM",
    name: "Single and custom",
  },
  {
    id: "TEXT",
    name: "Text",
  },
  {
    id: "RATING",
    name: "Rating",
  },
  {
    id: "DATE_BRACKETS",
    name: "Date Brackets",
  },
  {
    id: "YEAR",
    name: "Year",
  },
];

export const TENANT_ACCESS_TYPES = [
  {
    id: "Basic Access",
    name: "Basic Access",
  },
  {
    id: "Premium Access",
    name: "Premium Access",
  },
];

export const PUBLISHABLE_STATUSES = [
  {
    id: "Published",
    name: "Published",
  },
  {
    id: "Draft",
    name: "Draft",
  },
];

export const SECTION_BEHAVIORS = [
  {
    id: "GLOBAL",
    name: "Use Global Settings",
  },
  {
    id: "PREPEND_GLOBAL",
    name: "Use Global & Prepend Defined in Section IDs fields",
  },
  {
    id: "APPEND_GLOBAL",
    name: "Use Global & Append Defined in Section IDs fields",
  },
  {
    id: "CUSTOM",
    name: "Overwrite Global with Defined in Section IDs",
  },
];

export const AGE_BRACKETS = [
  {
    id: "",
    name: "Unknown",
  },
  {
    id: "18 - 24",
    name: "18 - 24",
  },
  {
    id: "25 - 34",
    name: "25 - 34",
  },
  {
    id: "35 - 44",
    name: "35 - 44",
  },
  {
    id: "45 - 54",
    name: "45 - 54",
  },
  {
    id: "55 - 64",
    name: "55 - 64",
  },
  {
    id: "65 +",
    name: "65 +",
  },
];

export const GENDERS = [
  {
    id: "",
    name: "Unknown",
  },
  {
    id: "Male",
    name: "Male",
  },
  {
    id: "Female",
    name: "Female",
  },
  {
    id: "Prefer not to say",
    name: "Prefer not to say",
  },
];

export const CUSTOM_SURVEY_TYPES = ["BARRIER_SURVEY", "FREQUENCY_SURVEY"];

export const PAYMENT_SYSTEMS = [
  {
    id: "Stripe",
    name: "Stripe",
  },
  {
    id: "GoCardless",
    name: "GoCardless",
  },
];

export const BILLING_MANUAL_INVOICE_STATUS = "Manual";

// export const VABOO_CLASSIC_APP_API_URL = "http://vaboo.local/remote-services/";

export const VABOO_CLASSIC_APP_API_URL =
  "https://api.vaboo.co.uk/remote-services/";

const ENV_APP_URLS = {
  // development: "https://control.vaboo.co.uk/",
  development: "https://vaboo-control-server.test/",
  // development: "https://vaboo-control-server-2025.test/",
  production: "https://control.vaboo.co.uk/",
};

export const APP_URL = ENV_APP_URLS[process.env.NODE_ENV];

export const API_URL = `${APP_URL}graphql`;

export const VABOO_REST_API_URL = `${APP_URL}api/v1/`;

export const FAILED_INVOICE_STATUSES = [
  "cancelled",
  "Debt Record",
  "failed",
  "Manual",
  "Overdue",
  "Users number error",
  "Billing disabled",
];

export const DEFAULT_PAYMENT_METHOD = "Stripe";

export const APOLLO_CACHE = new InMemoryCache({
  possibleTypes: {
    GenericFilter: ["Filter", "DatesFilter"],
  },
});
