import React from "react";
import Moment from "react-moment";
import {
  useParams,
  // useLocation,
} from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allInvoices } from "./../../graphql/queries/billingQueries";
import { deleteInvoicesMutation } from "./../../graphql/mutations/billingMutations";
import { generateAccessKeyMutation } from "./../../graphql/mutations/systemMutations";
import useBaseNavigation from "./../../hooks/baseNavigation";
import useListSelection from "./../../hooks/rowSelection";
import useNotification from "./../../hooks/notifications";
import useRootPageContext from "./../../hooks/rootPageContext";
import useModal from "./../../hooks/modal";
import {
  INVOICE_TYPE_MODEL_NAME,
  VABOO_CLASSIC_APP_API_URL,
} from "./../../configs/constants";
import { ConfirmActionModal } from "./../../mood-ui/Modals";
import {
  SuccessNotification,
  ErrorNotification,
} from "./../../mood-ui/Notifications";
import PageSpinner from "./../../mood-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
} from "./../../mood-ui/Layout";
import { TEditButton, AddButton } from "./../../mood-ui/Controls";
import { Heading } from "./../../mood-ui/Typography";
import Search from "./../../mood-ui/Search";
import { ListItemCheckbox, PageControls } from "./../../mood-ui/Controls";
import {
  ItemsNotFound,
  // ColorLabel,
} from "./../../mood-ui/Typography";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
  // TableActions,
  TableSelectedActions,
  Pagination,
} from "./../../mood-ui/DataTable";

import CustomLinkListActions from "./../../components/list-secondary-actions/CustomLinkListActions";
import useItemCounter from "../../hooks/itemCounter";

const ALL_INVOICES = gql(allInvoices);
const DELETE_INVOICES = gql(deleteInvoicesMutation);
const GENERATE_ACCESS_KEY = gql(generateAccessKeyMutation);

const VabooUserInvoiceList = ({
  viewItemPermission,
  createItemPermission,
  // deleteItemPermission,
  children,
}) => {
  const { id: ownerId, page, limit, orderBy, orderHow, search } = useParams();

  const {
    selectedRows,
    isRowSelected: rowSelected,
    toggleRow: toggleRowSelection,
    clearSelection,
    isAllRowsSelected,
    toggleAllRows,
  } = useListSelection();

  const { rootContext } = useRootPageContext();

  // const { search: queryString } = useLocation();

  const { modalState, showModal, closeModal } = useModal();

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const { data, loading, error, refetch } = useQuery(ALL_INVOICES, {
    variables: {
      page: parseInt(page),
      limit: parseInt(limit),
      orderBy,
      orderHow,
      search,
      queryString: `?owner=${ownerId}`,
    },
    fetchPolicy: "cache-and-network",
  });

  const { itemsTotal } = useItemCounter(data, { searchKey: "invoices" });

  const {
    nextPage,
    prevPage,
    editForm,
    viewForm,
    createForm,
    applySearch,
    // applyFilters,
    // resetFilters,
    // applySorting,
    // getCurrentUrl,
  } = useBaseNavigation(INVOICE_TYPE_MODEL_NAME, {
    urlContext: `${rootContext}/${ownerId}`,
    showingItemsCount: itemsTotal,
  });

  // console.log("url", getCurrentUrl());

  const [deleteInvoices, { loading: deletingInvoices }] = useMutation(
    DELETE_INVOICES,
    {
      onCompleted: (result) => {
        displayNotification({
          heading: "Invoices Deleted",
          message: "Successfully deleted selected invoices",
          secondsToDisplay: 1,
        });
        clearSelection();
        refetch();
      },
      onError: (e) => {
        displayErrorNotification({
          heading: "Error",
          message: "Failed to delete selected invoices",
          secondsToDisplay: 2,
        });
      },
    }
  );

  // const [generateAccessKey, { loading: generatingAccessKey }] = useMutation(
  const [generateAccessKey] = useMutation(GENERATE_ACCESS_KEY, {
    onCompleted: (result) => {
      window.open(
        `${VABOO_CLASSIC_APP_API_URL}download-bill/${result.generateAccessKey.code}`
      );
    },
    onError: (e) => {
      alert("Failed to generate file access key");
    },
  });

  const confirmDeletion = () => {
    showModal({
      heading: "Confirm Deletion",
      message:
        "Are you sure you want to DELETE selected invoices? Do not proceed if you are not sure.",
      mood: "Danger",
      confirmButtonLabel: "Delete",
      onConfirm: () => {
        closeModal();
        handleDeleteInvoices();
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  const handleDeleteInvoices = () => {
    deleteInvoices({ variables: { ids: selectedRows } });
  };

  const handleGenerateAccessKey = (invoiceId) => {
    generateAccessKey({
      variables: { params: JSON.stringify({ invoice_id: invoiceId }) },
    });
  };

  if (error) return <div>Error</div>;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="sborder-b border-[#dfe2e8] pb-4 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Invoices"
              description="All invoices that have been generated for this account."
            />
            {children}
          </Col>
        </Row>
        <Row tweaks="pt-3 pb-4 mt-0 px-5">
          <Col width={9}>
            {createItemPermission && (
              <AddButton
                label="Create new invoice"
                onClick={createForm}
                loading={loading}
              />
            )}
          </Col>
          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row>
        <TableContainer tweaks="px-5 pb-5">
          <TableView
            tweaks={
              loading ||
              !data ||
              (data && data.invoices && data.invoices.length === 0)
                ? "border-[#ebedf2]"
                : ""
            }
          >
            <THeading
              tweaks={
                loading ||
                !data ||
                (data && data.invoices && data.invoices.length === 0)
                  ? "opacity-30 border-b"
                  : ""
              }
            >
              <TRow>
                <THCol tweaks="w-4 text-center">
                  <ListItemCheckbox
                    onChange={() =>
                      toggleAllRows(
                        data?.invoices?.map((invoice) => invoice.id)
                      )
                    }
                    isChecked={
                      isAllRowsSelected(
                        data?.invoices?.map((invoice) => invoice.id)
                      )
                        ? true
                        : false
                    }
                  />
                </THCol>
                <THCol tweaks="text-left" name="id" value="ID" />
                <THCol tweaks="text-left" name="amount" value="Amount" />
                <THCol
                  tweaks="text-left"
                  name="description"
                  value="Description"
                />
                <THCol
                  topSticky="-40px"
                  tweaks="text-center pb-3"
                  name="payment_system"
                >
                  <span className="mdi mdi-credit-card text-[17px]"></span>
                </THCol>
                <THCol
                  tweaks="text-center"
                  name="last_billing_attempt_date"
                  value="Last attempt"
                />
                <THCol tweaks="text-center" name="status" value="Status" />
                <THCol tweaks="w-16 text-center" />
                <THCol tweaks="w-12 text-center" />
              </TRow>
            </THeading>
            {loading && (
              <tbody>
                <tr>
                  <td colSpan={"100%"}>
                    <PageSpinner inline extraStyles="h-[300px]" />
                  </td>
                </tr>
              </tbody>
            )}
            {!loading && data && data.invoices && data.invoices.length == 0 && (
              <tbody>
                <tr>
                  <td colSpan={"100%"}>
                    <ItemsNotFound
                      text="No previous invoices found."
                      tweaks="mx-5"
                    />
                  </td>
                </tr>
              </tbody>
            )}
            {!loading && data && data.invoices && data.invoices.length > 0 && (
              <TContent>
                {data.invoices.map((invoice) => (
                  <TRow key={invoice.id} isSelected={rowSelected(invoice.id)}>
                    <TCol tweaks="w-4 text-center">
                      <ListItemCheckbox
                        onChange={() => toggleRowSelection(invoice.id)}
                        isChecked={rowSelected(invoice.id) ? true : false}
                      />
                    </TCol>
                    <TCol
                      tweaks="font-mono slashed-zero tabular-nums text-left text-xs"
                      value={invoice.id}
                    />
                    <TCol
                      tweaks={`
                        ${
                          invoice?.amount == 0
                            ? "opacity-50"
                            : invoice?.amount > 0
                            ? "max-w-[100px]"
                            : " opacity-50"
                        }
                      `}
                    >
                      <div className="leading-none font-mono tracking-widest flex w-full justify-between orsdinal slashesd-zero tabular-nums px-1 text-xs">
                        <div className="mr-2">£ </div>
                        <div>
                          {invoice?.amount
                            ?.toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </div>
                      </div>
                    </TCol>
                    <TCol tweaks="max-w-[250px] font-mono slashed-zero tabular-nums text-left text-xs">
                      <abbr title={invoice.description}>
                        {invoice.description}
                      </abbr>
                    </TCol>
                    <TCol tweaks="w-[47px] text-center pr-0 pl-px pb-0">
                      {invoice.payment_system == "GoCardless" && (
                        <img
                          src="/images/gocardless.png"
                          alt=""
                          className={`-mt-2 mx-[10px] opacity-85 h-[24px] w-[24px] ${
                            rowSelected(invoice.id) ? "opacity-75" : ""
                          }`}
                        />
                      )}
                      {invoice.payment_system == "Stripe" && (
                        <img
                          src="/images/stripe.png"
                          alt=""
                          className={`-mt-2 mx-[10px] opacity-85 h-[24px] w-[24px] ${
                            rowSelected(invoice.id) ? "opacity-75" : ""
                          }`}
                        />
                      )}
                      {invoice.payment_system !== "Stripe" &&
                        invoice.payment_system !== "GoCardless" && (
                          <img
                            src="/images/no-payment-method.png"
                            alt=""
                            className={`-mt-1.5 mx-[13px] opacity-20 h-[16px] w-[16px] ${
                              rowSelected(invoice.id) ? "opacity-55" : ""
                            }`}
                          />
                        )}
                    </TCol>
                    <TCol tweaks="max-w-[40px] font-mono slashed-zero tabular-nums text-center uppercase text-xs ">
                      <Moment
                        format="DD&middot;MM&middot;YY | mm:hh"
                        date={invoice.last_billing_attempt_date}
                      />
                    </TCol>
                    <TCol
                      tweaks={`max-w-[140px] font-mono slashed-zero tabular-nums text-center uppercase text-xs
                      ${
                        invoice.status == "Paid" ||
                        invoice.status == "paid_out" ||
                        invoice.status == "confirmed"
                          ? " text-emerald-600 bg-emerald-200"
                          : invoice.status == "submitted"
                          ? " text-cyan-600 bg-cyan-200"
                          : invoice.status == "pending_submission"
                          ? " text-yellow-600 bg-yellow-200"
                          : invoice.status == "Overdue" ||
                            invoice.status == "Overdue_Old"
                          ? " text-orange-600 bg-orange-200"
                          : invoice.status == "failed" ||
                            invoice.status == "cancelled" ||
                            invoice.status == "Debt Record"
                          ? " text-rose-500 bg-rose-100"
                          : invoice.status == "Manual"
                          ? " text-indigo-500 bg-indigo-100"
                          : " text-gray-500 bg-gray-100"
                      }`}
                    >
                      {invoice.status}
                    </TCol>
                    <TCol tweaks="w-16 font-mono slashed-zero tabular-nums text-center text-xs">
                      {invoice.status === "Manual" && viewItemPermission && (
                        <TEditButton onClick={() => editForm(invoice.id)} />
                      )}
                      {invoice.status !== "Manual" && viewItemPermission && (
                        <TEditButton
                          onClick={() => viewForm(invoice.id)}
                          label="View"
                        />
                      )}
                    </TCol>
                    <TCol tweaks="w-12 font-mono slashed-zero tabular-nums text-center text-xs">
                      <TEditButton
                        onClick={() => handleGenerateAccessKey(invoice.id)}
                        label="PDF"
                      />
                    </TCol>
                  </TRow>
                ))}
              </TContent>
            )}
          </TableView>
          <PageControls spaced>
            <Row tweaks="w-full pl-5">
              <Col width={4}>
                <TableSelectedActions>
                  {selectedRows && selectedRows.length > 0 && (
                    <CustomLinkListActions
                      onClearSelection={clearSelection}
                      onDelete={confirmDeletion}
                    />
                  )}
                </TableSelectedActions>
              </Col>
              <Col width={5} extraStyles="">
                <div className="flex">
                  {/* dynamic list filter functionality here  */}
                </div>
              </Col>
              <Col width={3}>
                <Pagination onPrev={prevPage} onNext={nextPage} />
              </Col>
            </Row>
          </PageControls>
        </TableContainer>
      </BlockContainer>
      <ConfirmActionModal {...modalState} />
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default VabooUserInvoiceList;
