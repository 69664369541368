import React from "react";
// import Moment from "react-moment";
import {
  useParams,
  // useLocation,
} from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allCustomLinks } from "./../../graphql/queries/landlordQueries";
import { deleteCustomLinksMutation } from "./../../graphql/mutations/landlordMutations";
import Permissions, {
  landlordNoAccessFallbackList,
} from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import useBaseNavigation from "./../../hooks/baseNavigation";
import useListSelection from "./../../hooks/rowSelection";
import useNotification from "./../../hooks/notifications";
import useModal from "./../../hooks/modal";
import { CUSTOM_LINK_TYPE_MODEL_NAME } from "./../../configs/constants";
import { ConfirmActionModal } from "./../../mood-ui/Modals";
import {
  SuccessNotification,
  ErrorNotification,
} from "./../../mood-ui/Notifications";
import PageSpinner from "./../../mood-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
} from "./../../mood-ui/Layout";
import { PageControls, TEditButton, AddButton } from "./../../mood-ui/Controls";
import { Heading } from "./../../mood-ui/Typography";
import Search from "./../../mood-ui/Search";
import { ListItemCheckbox } from "./../../mood-ui/Controls";
import {
  ItemsNotFound,
  // ColorLabel,
} from "./../../mood-ui/Typography";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
  // TableActions,
  TableSelectedActions,
  Pagination,
} from "./../../mood-ui/DataTable";

import CustomLinkListActions from "./../../components/list-secondary-actions/CustomLinkListActions";

const ALL_CUSTOM_LINKS = gql(allCustomLinks);
const DELETE_CUSTOM_LINKS = gql(deleteCustomLinksMutation);

const LandlordCustomLinkList = () => {
  const { id: ownerId, page, limit, orderBy, orderHow, search } = useParams();
  const { protectPage, hasAccess } = usePermissions();
  protectPage(Permissions.Query.ClientCustomLinks, {
    noAccessFallbackList: landlordNoAccessFallbackList,
    variables: { id: ownerId },
  });
  const {
    selectedRows,
    isRowSelected: rowSelected,
    toggleRow: toggleRowSelection,
    clearSelection,
    isAllRowsSelected,
    toggleAllRows,
  } = useListSelection();
  // const { search: queryString } = useLocation();
  const { modalState, showModal, closeModal } = useModal();
  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();
  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();
  const { data, loading, error, refetch } = useQuery(ALL_CUSTOM_LINKS, {
    variables: {
      page: parseInt(page),
      limit: parseInt(limit),
      orderBy,
      orderHow,
      search,
      queryString: `?owner=${ownerId}`,
    },
    fetchPolicy: "cache-and-network",
  });
  const { nextPage, prevPage, editForm, createForm, applySearch } =
    useBaseNavigation(CUSTOM_LINK_TYPE_MODEL_NAME, {
      urlContext: `clients/${ownerId}`,
    });

  const [deleteCustomLinks] = useMutation(DELETE_CUSTOM_LINKS, {
    onCompleted: (result) => {
      displayNotification({
        heading: "Custom Links Deleted",
        message: "Successfully deleted selected custom links",
        secondsToDisplay: 1,
      });
      clearSelection();
      refetch();
    },
    onError: (e) => {
      displayErrorNotification({
        heading: "Error",
        message: "Failed to delete selected custom links",
        secondsToDisplay: 2,
      });
    },
  });

  const confirmDeletion = () => {
    showModal({
      heading: "Confirm Deletion",
      message:
        "Are you sure you want to DELETE selected custom links? Do not proceed if you are not sure.",
      mood: "Danger",
      confirmButtonLabel: "Delete",
      onConfirm: () => {
        closeModal();
        handleDeleteCustomLinks();
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  const handleDeleteCustomLinks = () => {
    deleteCustomLinks({ variables: { ids: selectedRows } });
  };

  if (error) return <div>Error</div>;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Custom links"
              description="Custom links are shown in the side menu of the member platform."
            />
          </Col>
        </Row>
        <Row tweaks="pt-5 pb-4 mt-0 px-5">
          <Col width={9}>
            {hasAccess(Permissions.Mutation.ManageCustomLinks) && (
              <AddButton
                label="Add custom link"
                onClick={createForm}
                loading={loading}
              />
            )}
          </Col>
          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row>
        <TableContainer tweaks="px-5 pb-5">
          <TableView
            tweaks={
              loading ||
              !data ||
              (data && data.customLinks && data.customLinks.length === 0)
                ? "border-[#ebedf2]"
                : ""
            }
          >
            <THeading
              tweaks={
                loading ||
                !data ||
                (data && data.customLinks && data.customLinks.length === 0)
                  ? "opacity-30 border-b"
                  : ""
              }
            >
              <TRow>
                <THCol tweaks="w-4 text-center">
                  <ListItemCheckbox
                    onChange={() =>
                      toggleAllRows(data?.customLinks?.map((cl) => cl.id))
                    }
                    isChecked={
                      isAllRowsSelected(data?.customLinks?.map((cl) => cl.id))
                        ? true
                        : false
                    }
                  />
                </THCol>
                <THCol tweaks="text-left" name="preview" value="Preview" />
                {/* <THCol tweaks="text-left" name="text" value="Text" /> */}
                {/* <THCol tweaks="text-left" name="color" value="Color" /> */}
                {/* <THCol tweaks="text-left" name="icon" value="Icon" /> */}
                <THCol tweaks="text-left" name="href" value="Link url" />
                <THCol tweaks="text-left" name="external" value="New tab" />
                <THCol
                  tweaks="text-left"
                  name="display_order"
                  value="Display order"
                />
                <THCol tweaks="w-16 text-center" />
              </TRow>
            </THeading>
            {loading && (
              <tbody>
                <tr>
                  <td colSpan={"100%"}>
                    <PageSpinner inline extraStyles="h-[300px]" />
                  </td>
                </tr>
              </tbody>
            )}
            {!loading &&
              data &&
              data.customLinks &&
              data.customLinks.length == 0 && (
                <tbody>
                  <tr>
                    <td colSpan={"100%"}>
                      <ItemsNotFound
                        text="No custom links found."
                        tweaks="mx-5"
                      />
                    </td>
                  </tr>
                </tbody>
              )}
            {!loading &&
              data &&
              data.customLinks &&
              data.customLinks.length > 0 && (
                <TContent>
                  {[...data?.customLinks]
                    ?.sort((a, b) =>
                      a.display_order > b.display_order
                        ? 1
                        : a.display_order < b.display_order
                        ? -1
                        : 0
                    )
                    // .sort((a,b) => a.display_order - b.display_order)
                    .map((cl) => (
                      <TRow key={cl.id} isSelected={rowSelected(cl.id)}>
                        <TCol tweaks="w-4 text-center">
                          <ListItemCheckbox
                            onChange={() => toggleRowSelection(cl.id)}
                            isChecked={rowSelected(cl.id) ? true : false}
                          />
                        </TCol>
                        <TCol>
                          <button
                            style={{ background: cl.color, color: "white" }}
                            className={`rounded justify-center py-2 px-3 border border-transparent text-sm leading-2 pointer-events-none flex gap-x-2`}
                          >
                            {cl.icon && cl.icon !== "" && (
                              <span
                                className={`text-[17px] mdi ${cl.icon}`}
                              ></span>
                            )}
                            {cl.text}
                          </button>
                        </TCol>
                        {/* <TCol value={cl.text} /> */}
                        {/* <TCol value={cl.color} /> */}
                        {/* <TCol value={cl.icon} /> */}
                        <TCol tweaks="max-w-[500px]">
                          <abbr title={cl.href}>{cl.href}</abbr>
                        </TCol>
                        <TCol>{cl.external ? "true" : "false"}</TCol>
                        <TCol value={cl.display_order} />
                        <TCol tweaks="w-16 text-center">
                          {hasAccess(Permissions.Query.ClientCustomLink) && (
                            <TEditButton onClick={() => editForm(cl.id)} />
                          )}
                        </TCol>
                      </TRow>
                    ))}
                </TContent>
              )}
          </TableView>
          <PageControls spaced>
            <Row tweaks="w-full pl-5">
              <Col width={4}>
                <TableSelectedActions>
                  {selectedRows && selectedRows.length > 0 && (
                    <CustomLinkListActions
                      onClearSelection={clearSelection}
                      onDelete={confirmDeletion}
                    />
                  )}
                </TableSelectedActions>
              </Col>
              <Col width={5} extraStyles="">
                <div className="flex">
                  {/* dynamic list filter functionality here  */}
                </div>
              </Col>
              <Col width={3}>
                <Pagination onPrev={prevPage} onNext={nextPage} />
              </Col>
            </Row>
          </PageControls>
        </TableContainer>
      </BlockContainer>
      <ConfirmActionModal {...modalState} />
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default LandlordCustomLinkList;
