import React, { useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { calculateClientStandardPricing } from "./../../../graphql/queries/billingQueries";
import { updateClientStandardPricingMutation } from "./../../../graphql/mutations/billingMutations";
import Permissions, {
  landlordNoAccessFallbackList,
} from "../../../configs/permissions";
import usePermissions from "../../../hooks/permissions";
import { FormControls, SaveButton } from "../../../mood-ui/Controls";
import PriceCalculator from "./PriceCalculator";

const UPDATE_PRICING = gql(updateClientStandardPricingMutation);
const CALCULATE_PRICE = gql(calculateClientStandardPricing);

const StandardPricing = ({
  clientId,
  paymentSettings,
  isDraft = true,
  parentFormHasErrors,
  onSuccess = () => {},
  onError = () => {},
}) => {
  const { protectPage, hasAccess } = usePermissions();
  protectPage(Permissions.Query.ClientPaymentSettings, {
    noAccessFallbackList: landlordNoAccessFallbackList,
    variables: { id: clientId },
  });

  const [computedPrice, setComputedPrice] = useState(0);

  const [update, { loading: isUpdating }] = useMutation(UPDATE_PRICING, {
    onCompleted: (result) => {
      console.dir(result);
      onSuccess();
    },
    onError: (e) => {
      console.dir(e);
      onError();
    },
  });

  const [calculatePrice] = useLazyQuery(CALCULATE_PRICE, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      setComputedPrice(result.calculateClientStandardPricing.amount);
    },
    onError: (e) => onError(),
  });

  const handleSave = () => {
    const data = { ...paymentSettings };
    delete data.__typename;
    delete data.account_id;
    update({
      variables: {
        ...data,
        maintance_fee_monthly_amount: parseFloat(
          data.maintance_fee_monthly_amount
        ),
        gifted_premium_access_fee_monthly: parseFloat(
          data.gifted_premium_access_fee_monthly
        ),
      },
    });
  };

  return (
    <React.Fragment>
      <FormControls spaced>
        <PriceCalculator
          paymentSettings={paymentSettings}
          computedPrice={computedPrice}
          onCalculate={calculatePrice}
        />
        {hasAccess(Permissions.Mutation.UpdateClientPaymentSettings) && (
          <SaveButton
            onClick={handleSave}
            loading={isUpdating}
            disabled={parentFormHasErrors() || !isDraft}
          />
        )}
      </FormControls>
    </React.Fragment>
  );
};

export default StandardPricing;
